import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { FormikInput } from '../common/forms/FormikInput';
import { changePassword } from '../../actions/authActions';
import { IReduxState } from '../../reducers';

interface IProps extends RouteComponentProps {
	loading: boolean;
	errors?: string[];
	changePassword: any;
}

interface IFormValues {
	password: string;
	confirm: string;
}

const validationSchema = Yup.object().shape({
	password: Yup.string().required('required'),
	confirm: Yup.string()
		.required('required')
		.oneOf([Yup.ref('password')], 'Passwords do not match')
});

const BaseMustChangePasswordForm: React.SFC<IProps> = ({ loading, errors, changePassword, history }) => (
	<Formik
		initialValues={{ password: '', confirm: '' }}
		onSubmit={({ password }: IFormValues) => changePassword(password, history)}
		validationSchema={validationSchema}
		render={(formikProps: FormikProps<IFormValues>) => {
			const hasErrors = Object.keys(formikProps.errors).length > 0;
			return (
				<form onSubmit={formikProps.handleSubmit}>
					<FormikInput
						type="password"
						name="password"
						placeholder="*********"
						label="New Password"
						icon="lock"
						iconPosition="left"
					/>
					<FormikInput
						type="password"
						name="confirm"
						placeholder="*********"
						label="Confirm New Password"
						icon="lock"
						iconPosition="left"
					/>
					{errors && (
						<div className="message is-danger">
							<div className="message-body">
								<p>Please resolve the following errors:</p>
								<ul>
									{errors.map(x => (
										<li key={x}>{x}</li>
									))}
								</ul>
							</div>
						</div>
					)}
					<div className="field">
						<div className="control">
							<button
								className={`button is-primary is-fullwidth ${loading ? 'is-loading' : ''}`}
								type="submit"
								disabled={hasErrors || loading}
							>
								Change Password
							</button>
						</div>
					</div>
				</form>
			);
		}}
	/>
);

const mapStateToProps = (state: IReduxState) => ({
	loading: !!state.loading.mustChangePassword,
	errors: state.errors.mustChangePassword
});

export const MustChangePasswordForm = connect(
	mapStateToProps,
	{ changePassword }
)(withRouter(BaseMustChangePasswordForm));
