import NavbarLogo from './navbar_logo.svg';
import Logo from './logo.jpg';
import LogoWithTagline from './logo-signature.jpg';

export interface ILogoCatalog {
	NavbarLogo: string;
	Logo: string;
	LogoWithTagline: string;
}

export const LogoCatalog: ILogoCatalog = {
	NavbarLogo,
	Logo,
	LogoWithTagline
};
