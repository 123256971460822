import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import { FormikInput } from '../common/forms/FormikInput';
import * as Yup from 'yup';
import { changePasswordWithToken } from '../../actions/authActions';
import { IReduxState } from '../../reducers';
import * as Routes from '../../routes';
import { toastr } from 'react-redux-toastr';
import Errors from '../common/Errors';

interface IFormValues {
	newPassword: string;
	confirmNewPassword: string;
	[key: string]: string;
}

const validationSchema = Yup.object().shape({
	newPassword: Yup.string()
		.required('New Password is required')
		.min(8, 'Passwords must be at least 8 characters long'),
	confirmNewPassword: Yup.string()
		.required('You must confirm your new password')
		.oneOf([Yup.ref('newPassword')], 'Passwords do not match')
});

const initialValues: IFormValues = {
	newPassword: '',
	confirmNewPassword: ''
};

interface IProps extends RouteComponentProps {
	loading: boolean;
	error?: string;
	changePasswordWithToken: any;
}

const BaseResetPasswordForm: React.SFC<IProps> = ({
	history,
	match,
	error: serverError,
	loading,
	changePasswordWithToken
}) => {
	const params: any = match.params;
	const { resetToken } = params;

	if (!resetToken) {
		history.push(Routes.LOGIN);
	}

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={({ newPassword }: IFormValues, { resetForm }) => {
				changePasswordWithToken(resetToken, newPassword, history, toastr);
				resetForm();
			}}
			render={(formikProps: FormikProps<IFormValues>) => {
				const formikErrors = Object.keys(formikProps.touched)
					.map(x => formikProps.errors[x])
					.filter(x => x);
				const errors = [...formikErrors, serverError].filter(x => x);

				return (
					<form onSubmit={formikProps.handleSubmit}>
						<FormikInput
							type="password"
							label="New Password"
							placeholder="***********"
							name="newPassword"
							icon="lock"
							iconPosition="left"
						/>
						<FormikInput
							type="password"
							label="Confirm Password"
							placeholder="***********"
							name="confirmNewPassword"
							icon="lock"
							iconPosition="left"
						/>
						<Errors errors={errors} />
						<button
							className={`button is-primary is-fullwidth ${loading ? 'is-loading' : ''}`}
							type="submit"
						>
							Reset My Password
						</button>
					</form>
				);
			}}
		/>
	);
};
const mapStateToProps = (state: IReduxState) => ({
	loading: state.loading.resetPassword,
	error: state.errors.resetPassword
});

export const ResetPasswordForm = connect(
	mapStateToProps,
	{ changePasswordWithToken }
)(withRouter(BaseResetPasswordForm));
