import React from 'react';
import { Link } from 'react-router-dom';
import { PageWithNavigation } from '../common/Navigation/PageWithNavigation';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import * as Routes from '../../routes';
import { LogoCatalog } from '../Logos';

export const ForgotPasswordPage: React.SFC = () => (
	<PageWithNavigation>
		<div className="ForgotPasswordPage__vertical-align">
			<div className="ForgotPasswordPage__container box">
				<img src={LogoCatalog.LogoWithTagline} alt="" />
				<hr />
				<p className="ForgotPasswordPage__description-text">
					Enter your email address and you'll receive a link to reset your password.
				</p>
				<ForgotPasswordForm />
				<Link className="ForgotPasswordPage__back-link" to={Routes.LOGIN}>
					Back to login
				</Link>
			</div>
		</div>
	</PageWithNavigation>
);
