import React from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Routes from '../../../routes';
import { IReduxState } from '../../../reducers';
import { logout } from '../../../actions/authActions';
import NavbarBranding from "../../Logos/NavbarBranding";

interface IProps extends RouteComponentProps<any> {
	loggedIn: boolean;
	logout: Function;
}

interface IState {
	isBurgerOpen: boolean;
	lastInteraction: Date;
	interactionInterval: number;
}

class NavbarClass extends React.Component<IProps, IState> {
	state: IState = {
		isBurgerOpen: false,
		lastInteraction: new Date(),
		interactionInterval: 0
	};

	updateInteraction = () => this.setState({ lastInteraction: new Date() });

	checkLastInteraction = () => {
		if (this.props.loggedIn) {
			const now = new Date().getTime();
			const timeSinceLastInteraction = now - this.state.lastInteraction.getTime();
			// Log out if no activity for 30 minutes
			if (timeSinceLastInteraction > 1800000) {
				console.log('Logging out...');
				this.props.logout();
			}
		}
	};

	componentDidMount() {
		document.addEventListener('click', this.updateInteraction);
		document.addEventListener('keydown', this.updateInteraction);
		const interval = window.setInterval(this.checkLastInteraction, 15000);
		this.setState({ interactionInterval: interval });
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.updateInteraction);
		document.removeEventListener('keydown', this.updateInteraction);
		clearInterval(this.state.interactionInterval);
	}

	renderNavLinks() {
		const { history, loggedIn } = this.props;

		const links = loggedIn
			? [
					{ route: Routes.DASHBOARD, label: 'Dashboard', icon: 'fa-tachometer-alt' },
					{ route: Routes.AGREEMENTS, label: 'Agreements', icon: 'fa-handshake' },
					{ route: Routes.DEMOS, label: 'Demos', icon: 'fa-car' },
					{ route: Routes.SETTINGS, label: 'Settings', icon: 'fa-cog' },
					{ route: Routes.CONTACT, label: 'Contact Us', icon: 'fa-phone' },
					{ route: Routes.LOGOUT, label: 'Logout', icon: 'fa-sign-out-alt' }
			  ]
			: [
					{ route: Routes.CONTACT, label: 'Contact Us', icon: 'fa-phone' },
					{ route: Routes.LOGIN, label: 'Login', icon: 'fa-sign-in-alt' }
			  ];

		return links.map(x => (
			<Link
				className={`navbar-item ${history.location.pathname === x.route ? 'active' : ''}`}
				to={x.route}
				key={x.route}
			>
				<div>{!!x.icon && <i className={`fas ${x.icon}`} />}</div>
				{x.label}
			</Link>
		));
	}

	render() {
		const { isBurgerOpen } = this.state;
		return (
			<section className="Navbar__section">
				<nav className="navbar" role="navigation" aria-label="main navigation">
					<div className="navbar-brand">
					<NavbarBranding />
						{/*eslint-disable-next-line*/}
						<a
							role="button"
							className={`navbar-burger burger ${isBurgerOpen && 'is-active'}`}
							aria-label="menu"
							aria-expanded="false"
							onClick={() => this.setState({ isBurgerOpen: !isBurgerOpen })}
						>
							<span aria-hidden="true" />
							<span aria-hidden="true" />
							<span aria-hidden="true" />
						</a>
					</div>
					<div className={`navbar-menu ${isBurgerOpen && 'is-active'}`}>
						<div className="navbar-end">{this.renderNavLinks()}</div>
					</div>
				</nav>
			</section>
		);
	}
}

const mapStateToProps = (state: IReduxState) => ({
	loggedIn: !!state.auth.user
});

export const Navbar = connect(
	mapStateToProps,
	{ logout }
)(withRouter(NavbarClass));
