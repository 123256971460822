import { Dispatch } from 'react';
import { IReduxState } from '../reducers';

export interface IAction {
	type: string;
	payload?: any;
}

export const ActionType = {
	GetToken: 'GetToken',
	GetUser: 'GetUser',
	GetUserByGuid: 'GetUserByGuid',
	Logout: 'Logout',
	Loading: 'Loading',
	Error: 'Error',
	GetDemos: 'GetDemos',
	GetDemoModels: 'GetDemoModels',
	RequestDemo: 'RequestDemo',
	GetFleetProfile: 'GetFleetProfile',
	GetAgreement: 'GetAgreement',
	GetAgreements: 'GetAgreements'
};

export type IActionCreator = (dispatch: Dispatch<IAction>, getState: () => IReduxState) => Promise<void>;
