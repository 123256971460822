import React from 'react';
import { IAgreement } from '../../../reducers/agreementReducer';

interface IProps {
	agreement: IAgreement;
}

export const ImportantNotes: React.SFC<IProps> = ({ agreement }) => (
	<tr>
		<th>Important note(s)</th>
		{notesFor(agreement)}
	</tr>
);

const notesFor = (agreement: IAgreement) => {
	switch (agreement.salesChannel) {
		case 'FSC1: Standard CH -Leasing Co.':
		case 'FSB1:Standard CH -Broker':
		case 'LRST: Leasing & Rental Special':
			return (
				<th>
					The support above is applicable to the cost Optional and Premium paint colours.
					<br />
					Special Edition models do not attract Business & Fleet Customer Support Terms
				</th>
			);

		case 'FSC2:Kia Contract Hire*':
			return <th>Special Edition models do not attract Business & Fleet Customer Support Terms</th>;

		case 'FSB2:Kia Approved Introducer*':
			return <th>Special Edition models do not attract Business & Fleet Customer Support Terms</th>;

		case 'PSL1:Public Sector Support L1':
		case 'PSST:Public Sector Special':
			return <th>Special Edition models do not attract Business & Fleet Customer Support Terms</th>;

		case 'PSL2:Public Sector Support L2':
			return <th>Special Edition models do not attract Business & Fleet Customer Support Terms</th>;

		case 'FSDR:Daily Rental':
		case 'DRL1:Dealer Rental Level 1':
			return (
				<th>
					The support above is applicable to the cost Optional and Premium paint colours.
					<br />
					Special Edition models do not attract Business & Fleet Customer Support Terms
				</th>
			);

		default:
			return (
				<th>
					The support above is applicable to the cost of Optional and Premium paint colours.
					<br />
					Special Edition models do not attract Business & Fleet Customer Support Terms unless otherwise
					stated.
				</th>
			);
	}
};
