import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IAgreement } from '../../reducers/agreementReducer';
import { PageWithNavigation } from '../common/Navigation/PageWithNavigation';
import { IReduxState } from '../../reducers';
import { getAgreement } from '../../actions/agreementActions';
import { KiaTemplate } from './templates/KiaTemplate';

interface IProps extends RouteComponentProps {
	loading: boolean;
	agreements: IAgreement[];
	getAgreement: any;
}

class BaseAgreementPage extends React.Component<IProps> {
	componentDidMount() {
		const params: any = this.props.match.params;
		this.props.getAgreement(params.agreementId);
	}

	render() {
		const { loading } = this.props;
		const params: any = this.props.match.params;

		if (loading) {
			return (
				<PageWithNavigation>
					<div className="container" style={{ marginTop: 40 }}>
						<div className="loader" />
					</div>
				</PageWithNavigation>
			);
		}

		return (
			<PageWithNavigation>
				<KiaTemplate agreementId={params.agreementId} history={this.props.history} />
			</PageWithNavigation>
		);
	}
}

const mapStateToProps = (state: IReduxState) => ({
	loading: state.loading.agreements
});

export const AgreementPage = connect(
	mapStateToProps,
	{ getAgreement }
)(withRouter(BaseAgreementPage));
