import React from 'react';
import { connect } from 'react-redux';
import { IDemo } from '../../../reducers/demoReducer';
import { formatDate } from '../../../dateHelpers';
import { IModelInfo } from '../../../reducers/demoModelReducer';
import { getDemoModels } from '../../../actions/demoActions';
import { IReduxState } from '../../../reducers';

interface IProps {
	rows: IDemo[];
	getDemoModels: any;
	models: IModelInfo[];
}

const daysBetween = (a: Date, b: Date) => Math.round((b.getTime() - a.getTime()) / (1000 * 60 * 60 * 24));

const toSentenceCase = (s: string): string => {
	return s
		.split(' ')
		.map(x => `${x.charAt(0).toLocaleUpperCase()}${x.substring(1).toLowerCase()}`)
		.join(' ');
};

interface ISortBy {
	startAsc: (a: IDemo, b: IDemo) => number;
	startDesc: (a: IDemo, b: IDemo) => number;
	endAsc: (a: IDemo, b: IDemo) => number;
	endDesc: (a: IDemo, b: IDemo) => number;
	driverAsc: (a: IDemo, b: IDemo) => number;
	driverDesc: (a: IDemo, b: IDemo) => number;
	statusAsc: (a: IDemo, b: IDemo) => number;
	statusDesc: (a: IDemo, b: IDemo) => number;
	[index: string]: (a: IDemo, b: IDemo) => number;
}

const sortBy: ISortBy = {
	startAsc: (a: IDemo, b: IDemo) => (a.start < b.start ? 1 : -1),
	startDesc: (a: IDemo, b: IDemo) => (a.start > b.start ? 1 : -1),
	endAsc: (a: IDemo, b: IDemo) => (a.end < b.end ? 1 : -1),
	endDesc: (a: IDemo, b: IDemo) => (a.end > b.end ? 1 : -1),
	driverAsc: (a: IDemo, b: IDemo) => (a.driverFirstName < b.driverFirstName ? 1 : -1),
	driverDesc: (a: IDemo, b: IDemo) => (a.driverFirstName > b.driverFirstName ? 1 : -1),
	statusAsc: (a: IDemo, b: IDemo) => (a.status < b.status ? 1 : -1),
	statusDesc: (a: IDemo, b: IDemo) => (a.status > b.status ? 1 : -1),
};

class BaseDemoTable extends React.Component<IProps> {
	state = {
		column: 'start',
		asc: true
	};

	componentDidMount() {
		this.props.getDemoModels();
	}

	toggleSort(column: string) {
		if (this.state.column !== column) {
			this.setState({ column, asc: false });
		} else {
			this.setState({ asc: !this.state.asc });
		}
	}

	renderSortIndicator = (column: string) => {
		const icon =
			this.state.column !== column ? 'fas fa-sort' : this.state.asc ? 'fas fa-sort-up' : 'fas fa-sort-down';
		return <i className={icon} />;
	};

	renderMobileDates(demo: IDemo) {
		const { start, end } = demo;
		if (!start || !end) return 'To be agreed';
		return `${formatDate(start)} for ${daysBetween(start, end)} days`;
	}

	renderStatus = (demo: IDemo, pullRight: boolean = false) => (
		<span className={`demo-status ${pullRight ? 'is-pulled-right' : ''}`}>{demo.status}</span>
	);

	thumbnailFor = (modelName: string): string => {
		let vehicle = this.props.models.find(
			x =>
				x.name.toLowerCase() === modelName.toLowerCase() || x.otherNames.indexOf(modelName.toLowerCase()) !== -1
		);
		return vehicle ? vehicle.thumbnail : '';
	};

	render() {
		const { column, asc } = this.state;
		const sortMethod: (a: IDemo, b: IDemo) => number = sortBy[column + (asc ? 'Asc' : 'Desc')];

		const { rows } = this.props;

		let validModels: string[] = [];
		this.props.models.forEach(x => {
			validModels = [...validModels, ...x.otherNames, x.name.toLowerCase()];
		});

		const filteredRows = rows.filter(x => validModels.indexOf(x.model.toLowerCase()) !== -1);
		const sortedRows = filteredRows.sort(sortMethod);

		return (
			<table className="demo-table">
				<thead>
					<tr>
						<th />
						<th>Model</th>
						<th onClick={() => this.toggleSort('driver')} style={{ cursor: 'pointer' }}>
							Driver {this.renderSortIndicator('driver')}
						</th>
						<th onClick={() => this.toggleSort('start')} style={{ cursor: 'pointer' }}>
							Start {this.renderSortIndicator('start')}
						</th>
						<th onClick={() => this.toggleSort('end')} style={{ cursor: 'pointer' }}>
							End {this.renderSortIndicator('end')}
						</th>
						<th>Length</th>
						<th onClick={() => this.toggleSort('status')} style={{ cursor: 'pointer' }}>
							Status {this.renderSortIndicator('status')}
						</th>
					</tr>
				</thead>
				<tbody>
					{sortedRows.map(x => (
						<tr key={x.id}>
							<td>
								<div className="model-image-holder">
									<img src={this.thumbnailFor(x.model)} alt="" />
								</div>
							</td>
							<td className="mobile-info">
								<div className="mobile-driver">
									{x.driverFirstName} {x.driverLastName} {this.renderStatus(x, true)}
								</div>
								<div className="mobile-dates">{this.renderMobileDates(x)}</div>
							</td>
							<td className="desktop-only">Kia {toSentenceCase(x.model)}</td>
							<td className="desktop-only">
								{x.driverFirstName} {x.driverLastName}
							</td>
							<td className="desktop-only">{x.start ? formatDate(x.start) : 'TBC'}</td>
							<td className="desktop-only">{x.end ? formatDate(x.end) : 'TBC'}</td>
							<td className="desktop-only">
								{x.start && x.end ? daysBetween(x.start, x.end) + ' days' : 'TBC'}
							</td>
							<td className="desktop-only">{x.status}</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	}
}

const mapStateToProps = (state: IReduxState) => ({
	models: state.demoModels
});

export const DemoTable = connect(
	mapStateToProps,
	{ getDemoModels }
)(BaseDemoTable);
