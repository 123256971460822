import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { PageWithNavigation } from '../common/Navigation/PageWithNavigation';
import { logout } from '../../actions/authActions';

interface IProps extends RouteComponentProps {
	logout: any;
}

class BaseLogoutPage extends React.Component<IProps> {
	componentDidMount() {
		const { logout, history } = this.props;
		logout(history);
	}

	render() {
		return (
			<PageWithNavigation>
				<div className="loader" />
			</PageWithNavigation>
		);
	}
}

export const LogoutPage = connect(
	null,
	{ logout }
)(withRouter(BaseLogoutPage));
