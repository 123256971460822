import { IAction, ActionType } from '../actions/types';

export interface ILoadingState {
	agreements: boolean;
	login: boolean;
	changePassword: boolean;
	mustChangePassword: boolean;
	resetPassword: boolean;
	demos: boolean;
	requestDemo: boolean;
	fleetProfile: boolean;
}

const initialState = {
	agreements: false,
	login: false,
	changePassword: false,
	mustChangePassword: false,
	resetPassword: false,
	demos: false,
	requestDemo: false,
	fleetProfile: false
};

export const loadingReducer = (state: ILoadingState = initialState, { type, payload }: IAction) => {
	switch (type) {
		case ActionType.Loading:
			return { ...state, ...payload };

		default:
			return state;
	}
};
