import { IAction, ActionType } from '../actions/types';

export interface IFleetProfile {
	fleetSize?: number;
	numberOfCars?: number;
	numberOfVans?: number;
	purchaseMethod?: string[];
	changeCycleMonths?: string[];
	currentFunders?: string[];
}

const initialState = {
	fleetSize: undefined,
	numberOfCars: undefined,
	numberOfVans: undefined,
	purchaseMethod: undefined,
	changeCycleMonths: undefined,
	currentFunders: undefined
};

export const fleetProfileReducer = (state: IFleetProfile = initialState, { type, payload }: IAction) => {
	switch (type) {
		case ActionType.GetFleetProfile:
			return payload;

		default:
			return state;
	}
};
