import React from 'react';
import axios from 'axios';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { FormikInput } from '../common/forms/FormikInput';
import * as Routes from '../../routes';
import './SetupAccountForm.scss';
import apiEndpoint from '../../actions/api';

const rootUrl = apiEndpoint;

const validationSchema: Yup.ObjectSchema<any> = Yup.object().shape({
	email: Yup.string().required('Email is required'),
	password: Yup.string()
		.required('Password is required')
		.min(8, 'Password must be at least 8 characters'),
	confirmPassword: Yup.string()
		.required('Confirm password is required')
		.min(8)
		.oneOf([Yup.ref('password')], 'Password and confirm password do not match')
});

interface IFormValues {
	email: string;
	password: string;
	confirmPassword: string;
}

const initialValues: IFormValues = {
	email: '',
	password: '',
	confirmPassword: ''
};

interface IProps extends RouteComponentProps {
	email?: string;
	crmToken?: string;
}

export const BaseSetupAccountForm: React.SFC<IProps> = ({ email, crmToken, history }) => {
	const initialFormValues = {
		...initialValues,
		email: email || initialValues.email
	};

	return (
		<Formik
			initialValues={initialFormValues}
			validationSchema={validationSchema}
			onSubmit={async (values: IFormValues, { resetForm }) => {
				const { password } = values;
				await axios.post(`${rootUrl}/auth/changePasswordWithToken`, { token: crmToken, newPassword: password });
				history.push(Routes.LOGIN);
				toastr.success('Password created successfully', 'You can now log in with your new password');
			}}
			render={(formikProps: FormikProps<IFormValues>) => (
				<form onSubmit={formikProps.handleSubmit} className="setup-account-form" style={{ marginTop: '2em' }}>
					<FormikInput
						type="email"
						placeholder="your.email@your-company.co.uk"
						name="email"
						label="Email Address"
						icon="user"
						iconPosition="left"
						disabled={!!initialFormValues.email}
					/>
					<FormikInput
						type="password"
						name="password"
						placeholder="*********"
						label="New Password"
						icon="lock"
						iconPosition="left"
					/>
					<FormikInput
						type="password"
						name="confirmPassword"
						placeholder="*********"
						label="Confirm Password"
						icon="lock"
						iconPosition="left"
						style={{ marginBottom: '2em' }}
					/>
					{Object.values(formikProps.errors).length > 0 && formikProps.submitCount > 0 && (
						<div className="errors">
							Please fix the following errors:
							<ul>
								{Object.values(formikProps.errors).map(x => (
									<li>{x}</li>
								))}
							</ul>
						</div>
					)}
					<div className="field">
						<div className="control">
							<button
								className={`button is-primary is-fullwidth ${false /* loading */ ? 'is-loading' : ''}`}
								type="submit"
							>
								Create my password
							</button>
						</div>
					</div>
				</form>
			)}
		/>
	);
};

export const SetupAccountForm = withRouter(BaseSetupAccountForm);
