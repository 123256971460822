import React from 'react';
import { Field as FormikField, FieldProps } from 'formik';

interface IProps {
	name: string;
	label?: string;
	toggle?: boolean;
}

export const FormikCheckbox: React.SFC<IProps> = ({ label, name }) => (
	<FormikField
		render={({ form: { values, handleChange } }: FieldProps) => (
			<div className="field">
				<label className="checkbox">
					<input type="checkbox" name={name} checked={values[name]} onChange={handleChange} />
					&nbsp;{label}
				</label>
			</div>
		)}
	/>
);
