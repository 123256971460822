export const formatDateRange = (start?: Date, end?: Date) =>
	start && end ? `${formatDate(start)} - ${formatDate(end)}` : '';

export const formatDate = (date: Date) => {
	const dayOfMonth = date.getDate().toString();

	const lastChar = dayOfMonth.charAt(dayOfMonth.length - 1);
	const th =
		lastChar === '1' && dayOfMonth !== '11'
			? 'st'
			: lastChar === '2' && dayOfMonth !== '12'
			? 'nd'
			: lastChar === '3' && dayOfMonth !== '13'
			? 'rd'
			: 'th';

	return `${dayOfMonth}${th} ${months[date.getMonth()]} ${date.getFullYear()}`;
};

export const monthYearFormat = (date: Date) => `${months[date.getMonth()]} ${date.getFullYear()}`;

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
