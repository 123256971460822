import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IReduxState } from '../../reducers';
import { IAgreement } from '../../reducers/agreementReducer';

interface IProps {
	agreementId?: string;
}

const BaseTermsHaveChangedModal: React.SFC<IProps> = ({ agreementId }) => {
	if (!agreementId) {
		return null;
	}

	return (
		<div className="modal-overlay">
			<div className="terms-have-changed-modal">
				<p>The terms of your active agreement have changed.</p>
				<p>
					<Link to={`/agreement/${agreementId}`}>Click here to read and agree to your new terms</Link>
				</p>
			</div>
		</div>
	);
};

const mapStateToProps = (state: IReduxState): IProps => {
	const agreementToShow = (state.agreements || []).find(x => showAgreement(x));
	return {
		agreementId: agreementToShow ? agreementToShow.id : undefined
	};
};

const showAgreement = (agreement: IAgreement): boolean => {
	//don't show dialog for types 19
	const noDialogForType = ['19'];
	const noDialogForStatus = ['Declined', 'Expired', 'Suspended'];
	return (
		!(noDialogForStatus.indexOf((agreement.status || '').trim()) !== -1)
		&& !agreement.cxAcknowledgementDate
		&& !noDialogForType.includes(agreement.termsIssueType || '')
		);
};

export const TermsHaveChangedModal = connect(mapStateToProps)(BaseTermsHaveChangedModal);
