export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const FORGOT_PASSWORD = '/forgot';
export const MUST_CHANGE_PASSWORD = '/mustChangePassword';
export const DASHBOARD = '/dash';
export const SETTINGS = '/settings';
export const REQUEST_DEMO = '/requestDemo';
export const DEMOS = '/demos';
export const AGREEMENTS = '/agreements';
export const AGREEMENT = '/agreement/:agreementId';
export const CONTACT = '/contact';
export const RESET_PASSWORD = '/resetPassword/:resetToken';
export const SETUP_ACCOUNT = '/acct/:crmToken';
export const SETUP_ACCOUNT_REDIRECT = '/acct/';

export const buildRoute = (route: string, params: any) => {
	let result = route;

	Object.keys(params).forEach(k => {
		result = result.replace(`:${k}`, params[k]);
	});

	return result;
};
