import { ToastrEmitter } from 'react-redux-toastr';
import { Api, IApi } from './api';
import { Dispatch } from 'react';
import { IAction, ActionType } from './types';
import { IReduxState } from '../reducers';
import { IDriverDetails } from '../components/demos/forms/DemoDriverForm';
import * as Routes from '../routes';
import { History } from 'history';

export const getDemoModels = () => async (dispatch: Dispatch<IAction>, getState: () => IReduxState) => {
	dispatch({ type: ActionType.Loading, payload: { demoVehicles: true } });
	const api: IApi = new Api(getState);
	try {
		const { data } = await api.get('/demos/models');

		dispatch({
			type: ActionType.GetDemoModels,
			payload: data
		});
	} catch (err) {
		console.log(err);
		api.put('/log', { contents: `CLIENT Unable to get demos: ${JSON.stringify(err)}` });
	} finally {
		dispatch({ type: ActionType.Loading, payload: { demoVehicles: false } });
	}
};

export const getDemos = () => async (dispatch: Dispatch<IAction>, getState: () => IReduxState) => {
	dispatch({ type: ActionType.Loading, payload: { demos: true } });
	const api: IApi = new Api(getState);
	try {
		const { data } = await api.get('/demos/list');

		const demos = data.map((d: any) => ({
			...d,
			start: d.start ? new Date(d.start) : null,
			end: d.end ? new Date(d.end) : null
		}));

		dispatch({ type: ActionType.GetDemos, payload: demos });
	} catch (err) {
		console.log(err);
		api.put('/log', { contents: `CLIENT Unable to get demos: ${JSON.stringify(err)}` });
	} finally {
		dispatch({ type: ActionType.Loading, payload: { demos: false } });
	}
};

export const requestDemo = (
	model: string,
	transmission: string,
	driver: IDriverDetails,
	history: History,
	toastr: ToastrEmitter
) => async (dispatch: Dispatch<IAction>, getState: () => IReduxState) => {
	dispatch({ type: ActionType.Loading, payload: { requestDemo: true } });
	const api: IApi = new Api(getState);
	try {
		const demoRequest = {
			model,
			transmission,
			driver
		};
		const { data } = await api.put('/demos', demoRequest);

		dispatch({
			type: ActionType.RequestDemo,
			payload: {
				...data,
				start: data.start ? new Date(data.start) : null,
				end: data.end ? new Date(data.end) : null
			}
		});
		history.push(Routes.DEMOS);
		toastr.success(
			'Demo Requested',
			`${driver.firstName}'s demo has been requested. Someone will be in touch soon.`
		);
	} catch (err) {
		console.log(err);
		api.put('/log', { contents: `CLIENT Unable to request demo: ${JSON.stringify(err)}` });
	} finally {
		dispatch({ type: ActionType.Loading, payload: { requestDemo: false } });
	}
};
