import { IReduxState } from './../reducers/index';
import { Dispatch } from 'redux';
import { IAction, ActionType } from './types';
import { Api, IApi } from './api';

export const getFleetProfile = () => async (dispatch: Dispatch<IAction>, getState: () => IReduxState) => {
	dispatch({ type: ActionType.Loading, payload: { fleetProfile: true } });
	const api: IApi = new Api(getState);
	try {
		const { data } = await api.get('/fleetProfile');

		dispatch({
			type: ActionType.GetFleetProfile,
			payload: data
		});
	} catch (err) {
		console.error(err);
		api.put('/log', { contents: `CLIENT Unable to get fleet profile: ${JSON.stringify(err)}` });
	} finally {
		dispatch({ type: ActionType.Loading, payload: { fleetProfile: false } });
	}
};
