import React from 'react';

interface IProps {
	initiallyCollapsed?: boolean;
	title: string;
	children?: any;
}

interface IState {
	collapsed: boolean;
}

export class MobileCollapsibleSection extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			collapsed: !!props.initiallyCollapsed
		};
	}

	toggleCollapsed = () => this.setState({ collapsed: !this.state.collapsed });

	render() {
		const { title, children } = this.props;
		const { collapsed } = this.state;
		return (
			<section className="mobile-collapsible-section">
				<div className="section-title" onClick={() => this.toggleCollapsed()}>
					<i className={`fa fa-chevron-down ${collapsed ? 'rotated' : ''} mobile-only`} /> {title}
				</div>
				<div className={`section-content ${collapsed ? 'collapsed' : ''}`}>{children || ''}</div>
			</section>
		);
	}
}
