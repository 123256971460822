import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IAgreement } from '../../reducers/agreementReducer';
import * as Routes from '../../routes';
import { formatDate, monthYearFormat } from '../../dateHelpers';

interface IProps extends RouteComponentProps {
	rows: IAgreement[];
}

interface ISortBy {
	startAsc: (a: IAgreement, b: IAgreement) => number;
	startDesc: (a: IAgreement, b: IAgreement) => number;
	endAsc: (a: IAgreement, b: IAgreement) => number;
	endDesc: (a: IAgreement, b: IAgreement) => number;
	agreedAsc: (a: IAgreement, b: IAgreement) => number;
	agreedDesc: (a: IAgreement, b: IAgreement) => number;
	statusAsc: (a: IAgreement, b: IAgreement) => number;
	statusDesc: (a: IAgreement, b: IAgreement) => number;
	[index: string]: (a: IAgreement, b: IAgreement) => number;
}

const sortBy: ISortBy = {
	startAsc: (a: IAgreement, b: IAgreement) => (a.start < b.start ? 1 : -1),
	startDesc: (a: IAgreement, b: IAgreement) => (a.start > b.start ? 1 : -1),
	endAsc: (a: IAgreement, b: IAgreement) => (a.end < b.end ? 1 : -1),
	endDesc: (a: IAgreement, b: IAgreement) => (a.end > b.end ? 1 : -1),
	agreedAsc: (a: IAgreement, b: IAgreement) => {
		if (!a.cxAcknowledgementDate) return 1;
		if (!b.cxAcknowledgementDate) return -1;

		return a.cxAcknowledgementDate < b.cxAcknowledgementDate ? 1 : -1;
	},
	agreedDesc: (a: IAgreement, b: IAgreement) => {
		if (!a.cxAcknowledgementDate) return -1;
		if (!b.cxAcknowledgementDate) return 1;

		return a.cxAcknowledgementDate > b.cxAcknowledgementDate ? 1 : -1;
	},
	statusAsc: (a: IAgreement, b: IAgreement) => (a.status < b.status ? 1 : -1),
	statusDesc: (a: IAgreement, b: IAgreement) => (a.status > b.status ? 1 : -1)
};

class BaseAgreementsTable extends React.Component<IProps> {
	state = {
		column: 'start',
		asc: true
	};

	toggleSort(column: string) {
		if (this.state.column !== column) {
			this.setState({ column, asc: false });
		} else {
			this.setState({ asc: !this.state.asc });
		}
	}

	renderSortIndicator(column: string) {
		const icon =
			this.state.column !== column ? 'fas fa-sort' : this.state.asc ? 'fas fa-sort-up' : 'fas fa-sort-down';
		return <i className={icon} />;
	}

	renderAgreedDate(agreement: IAgreement) {
		if (agreement.cxAcknowledgementDate !== undefined) {
			return formatDate(agreement.cxAcknowledgementDate);
		} else if ((agreement.status || '').trim() === 'Expired') {
			return '';
		} else {
			return (
				<span>
					<i className="fas fa-exclamation-triangle" style={{ color: 'red' }} /> Not Agreed
				</span>
			);
		}
	}

	render() {
		const { column, asc } = this.state;
		const { rows, history } = this.props;
		const sortMethod: (a: IAgreement, b: IAgreement) => number = sortBy[column + (asc ? 'Asc' : 'Desc')];

		const sortedRows = rows.sort(sortMethod);

		return (
			<table className="agreements-table">
				<thead>
					<tr>
						<th onClick={() => this.toggleSort('start')} style={{ cursor: 'pointer' }}>
							Start Date {this.renderSortIndicator('start')}
						</th>
						<th onClick={() => this.toggleSort('end')} style={{ cursor: 'pointer' }}>
							End Date {this.renderSortIndicator('end')}
						</th>
						<th onClick={() => this.toggleSort('agreed')} style={{ cursor: 'pointer' }}>
							Terms Agreed Date {this.renderSortIndicator('agreed')}
						</th>
						<th onClick={() => this.toggleSort('status')} style={{ cursor: 'pointer' }}>
							Status {this.renderSortIndicator('status')}
						</th>
					</tr>
				</thead>
				<tbody>
					{sortedRows.map(row => {
						const route = Routes.buildRoute(Routes.AGREEMENT, { agreementId: row.id });
						return (
							<tr key={row.id} onClick={() => history.push(route)}>
								<td className="desktop-only">{formatDate(row.start)}</td>
								<td className="desktop-only">{formatDate(row.end)}</td>
								<td className="desktop-only">{this.renderAgreedDate(row)}</td>
								<td>{row.status}</td>
								<td className="mobile-only">
									From {monthYearFormat(row.start)} to {monthYearFormat(row.end)}
								</td>
								<td className="mobile-only">
									<i className="fas fa-chevron-right" />
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}
}

interface IStatusPillProps {
	status: string;
}

// const StatusPill = ({ status }: IStatusPillProps) => (
// 	<span className={`agreement-status ${greenStatuses.indexOf(status) !== -1 ? 'green' : ''}`}>{status}</span>
// );

// const greenStatuses = ['Approved', 'Pending', 'Pending NBSM Approval', 'Pending RBSM Approval'];

export const AgreementsTable = withRouter(BaseAgreementsTable);
