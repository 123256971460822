import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import { toastr } from 'react-redux-toastr';
import * as Yup from 'yup';
import { FormikInput } from '../common/forms/FormikInput';
import { login } from '../../actions/authActions';
import { IReduxState } from '../../reducers';
import { FormikCheckbox } from '../common/forms/FormikCheckbox';
import Errors from '../common/Errors';

const validationSchema: Yup.ObjectSchema<any> = Yup.object().shape({
	email: Yup.string()
		.email("This doesn't look like a valid email")
		.required('required'),
	password: Yup.string().required('required')
});

interface IFormValues {
	email: string;
	password: string;
	rememberMe: boolean;
}

const initialValues: IFormValues = {
	email: process.env.REACT_APP_DEFAULT_USERNAME || '',
	password: process.env.REACT_APP_DEFAULT_PASSWORD || '',
	rememberMe: true
};

interface IProps extends RouteComponentProps<any> {
	loading: boolean;
	error?: string;
	login: any;
}

export const BaseLoginForm: React.SFC<IProps> = ({ login, loading, match: { params }, history, error }) => (
	<Formik
		initialValues={initialValues}
		validationSchema={validationSchema}
		onSubmit={({ email, password, rememberMe }: IFormValues, { resetForm }) => {
			login(email, password, rememberMe, history, toastr);
			resetForm();
		}}
		render={(formikProps: FormikProps<IFormValues>) => (
			<form onSubmit={formikProps.handleSubmit} className="login-form">
				<FormikInput
					type="email"
					placeholder="john.doe@your-company.co.uk"
					name="email"
					label="Email"
					icon="user"
					iconPosition="left"
				/>
				<FormikInput
					type="password"
					name="password"
					placeholder="*********"
					label="Password"
					icon="lock"
					iconPosition="left"
				/>
				<FormikCheckbox name="rememberMe" label="Remember Me" />
				<Errors errors={error ? [error] : []} />
				<div className="field">
					<div className="control">
						<button
							className={`button is-primary is-fullwidth ${loading ? 'is-loading' : ''}`}
							type="submit"
						>
							Log in
						</button>
					</div>
				</div>
			</form>
		)}
	/>
);

const mapStateToProps = (state: IReduxState) => ({
	loading: !!state.loading.login,
	error: state.errors.login
});

export const LoginForm = connect(
	mapStateToProps,
	{ login }
)(withRouter(BaseLoginForm));
