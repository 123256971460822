import React from 'react';
import NavbarLogo from './navbar_logo.png';
import {  Link } from 'react-router-dom';
import * as Routes from '../../routes';

function NavbarBranding() {
    return (
        <div>
            <Link to={Routes.DASHBOARD} className="Navbar__logo-item navbar-item">			
             <img alt="logo" className="brand-image" src={NavbarLogo} />
             <span className="pipe-border" />
             <span className="navbar-logo-text">Business</span>
            </Link>
        </div>
    );
}

export default NavbarBranding;