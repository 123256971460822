import React from 'react';
import { PageWithNavigation } from '../common/Navigation/PageWithNavigation';
import { MustChangePasswordForm } from './MustChangePasswordForm';

export const MustChangePasswordPage: React.SFC = () => (
	<PageWithNavigation>
		<div className="MustChangePasswordPage__vertical-align">
			<div className="MustChangePasswordPage__container box">
				<h3 className="is-size-4 has-text-centered has-text-weight-bold">Change Your Password</h3>
				<hr />
				<p className="MustChangePasswordpage__change-information">
					Before you continue, you must change your password.
				</p>
				<MustChangePasswordForm />
			</div>
		</div>
	</PageWithNavigation>
);
