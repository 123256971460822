import React from 'react';
import { Field as FormikField, FieldProps } from 'formik';

export interface IDropdownOption {
	text: string;
	value: any;
	icon?: string;
}

interface IProps {
	label?: string;
	name: string;
	placeholder?: string;
	options: IDropdownOption[];
}

export const FormikDropdown: React.SFC<IProps> = ({ name, label, options, placeholder }) => (
	<FormikField
		render={({ form: { handleChange, values } }: FieldProps) => {
			return (
				<div className="field">
					<label className="label">{label}</label>
					<div className="control">
						<div className="select is-fullwidth">
							<select
								name={name}
								value={values[name] || ''}
								onChange={handleChange}
							>
								<option disabled value="">{placeholder}</option>
								{options.map(option => <option key={option.value} value={option.value}>{option.text}</option>)}
							</select>
						</div>
					</div>
				</div>
			);
		}}
	/>
);
