import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { PageWithNavigation } from '../common/Navigation/PageWithNavigation';
import { DemoDriverForm, IDriverDetails } from './forms/DemoDriverForm';
import { requestDemo, getDemoModels } from '../../actions/demoActions';
import { IReduxState } from '../../reducers';
import { IModelInfo } from '../../reducers/demoModelReducer';

export type Transmission = 'Manual' | 'Automatic' | undefined;

interface IRequestDemoPageState {
	selectedModel?: IModelInfo;
	hoveredModel?: IModelInfo;
	transmission: Transmission;
	driverDetails?: IDriverDetails;
	startDate?: Date;
	endDate?: Date;
	datesConfirmed: boolean;
}

const defaultPageState: IRequestDemoPageState = {
	hoveredModel: undefined,
	selectedModel: undefined,
	transmission: undefined,
	driverDetails: undefined,
	startDate: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
	endDate: new Date(Date.now() + 1000 * 60 * 60 * 24 * 14),
	datesConfirmed: false
};

interface IProps extends RouteComponentProps {
	requestDemo: any;
	getDemoModels: any;
	models: IModelInfo[];
	loading: boolean;
}

class BaseRequestDemoPage extends React.Component<IProps> {
	state = {
		...defaultPageState,
		pageIndex: 0
	};

	componentDidMount() {
		this.props.getDemoModels();
	}

	requestClick() {
		const { selectedModel, transmission, driverDetails } = this.state;
		if (!selectedModel) return;

		this.props.requestDemo(selectedModel.name, transmission, driverDetails, this.props.history, toastr);
	}

	goForward = () => this.setState({ pageIndex: this.state.pageIndex + 1 });
	goBack = () => this.setState({ pageIndex: this.state.pageIndex - 1 });

	render() {
		const models = this.props.models.filter(x => !!x.allowRequests).sort((a, b) => (a.order > b.order ? 1 : -1));
		const { selectedModel, pageIndex, transmission } = this.state;
		return (
			<PageWithNavigation>
				<div className="request-demo-page-header">
					<h2>Request a Demo</h2>
				</div>
				<div className="container">
					{pageIndex === 0 && (
						<div className="box">
							<div className="columns is-multiline">
								{models.map((vehicle: IModelInfo) => {
									const isSelected = vehicle === selectedModel;
									return (
										<div key={vehicle.name} className="column is-4 has-text-centered">
											<div
												className={`RequestDemoPage__vehicle ${
													isSelected ? 'RequestDemoPage__vehicle--active' : ''
												}`}
												onClick={() => this.setState({ selectedModel: vehicle })}
											>
												<div className="RequestDemoPage__vehicle__holder">
													<img
														alt=""
														src={vehicle.thumbnail}
														style={{
															margin: '0px auto',
															maxWidth: 250,
															backgroundColor: 'transparent'
														}}
													/>
												</div>
												<h4 className="has-text-weight-bold">{vehicle.name}</h4>
												<div
													className="has-text-left"
													style={{ display: isSelected ? 'inherit' : 'none' }}
												>
													<div className="RequestDemoPage__vehicle__description">
														{vehicle.facts.map(fact => (
															<p>
																<i
																	className={`fas fa-${
																		fact.icon
																	} RequestDemoPage__vehicle__description__icon`}
																/>{' '}
																{fact.text}
															</p>
														))}
													</div>
													<button
														className="RequestDemoPage__vehicle__try-button button is-fullwidth"
														onClick={this.goForward}
													>
														Try this car <i className="fas fa-chevron-right" />
													</button>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					)}
					{pageIndex === 1 && selectedModel && (
						<div className="RequestDemoPage__container box">
							<div className="RequestDemoPage__header">
								<div className="RequestDemoPage__header__inner">
									<img
										className="RequestDemoPage__header__image"
										src={selectedModel.thumbnail}
										alt=""
									/>
									<h2 className="is-size-1 has-text-weight-bold">Kia {selectedModel.name}</h2>
								</div>
							</div>
							<div className="RequestDemoPage__content">
								<div className="RequestDemoPage__transmission">
									<TransmissionOption
										active={transmission === 'Manual'}
										onClick={() => this.setState({ transmission: 'Manual' })}
										transmission="Manual"
									/>
									<div className="RequestDemopage__transmission__spacer" />
									<TransmissionOption
										active={transmission === 'Automatic'}
										onClick={() => this.setState({ transmission: 'Automatic' })}
										transmission="Automatic"
									/>
								</div>
								<DemoDriverForm
									onSubmit={(driverDetails: IDriverDetails) =>
										this.setState({ driverDetails }, () => this.requestClick())
									}
									onBack={this.goBack}
									transmissionSelected={!!transmission}
								/>
							</div>
						</div>
					)}
				</div>
			</PageWithNavigation>
		);
	}
}

interface ITransmissionOptionProps {
	active: boolean;
	onClick: () => void;
	transmission: Transmission;
}

const TransmissionOption: React.SFC<ITransmissionOptionProps> = ({ active, onClick, transmission }) => (
	<button className={`button RequestDemoPage__transmission__button ${active ? 'active' : ''}`} onClick={onClick}>
		<i className={`fas fa-${active ? 'check-circle' : 'times-circle'}`} /> {transmission}
	</button>
);

const mapStateToProps = (state: IReduxState) => ({
	loading: state.loading.requestDemo,
	models: state.demoModels
});

export const RequestDemoPage = connect(
	mapStateToProps,
	{ requestDemo, getDemoModels }
)(withRouter(BaseRequestDemoPage));
