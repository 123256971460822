import React from 'react';
import { Navbar } from './Navbar';

interface IProps {
	children: React.ReactNode;
}

export const PageWithNavigation: React.SFC<IProps> = ({ children }) => (
	<>
		<Navbar />
		{children}
	</>
);
