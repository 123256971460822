import { IAction, ActionType } from '../actions/types';

export interface IAgreement {
	id: string;
	status: string;
	ucn: string;
	companyName: string;
	start: Date;
	end: Date;
	termsIssueType?: string;
	cxAcknowledgementDate?: Date;
	support: ISupport[];
	salesChannel: string;
	specialTermsNotes?: string;
	saleRoiTermsAndConditions?: string;
}

export interface ISupport {
	descriptor: string;
	support: number;
}

export const agreementReducer = (state: IAgreement[] = [], { type, payload }: IAction) => {
	switch (type) {
		case ActionType.GetAgreement:
			return [...state.filter(x => x.id !== payload.id), payload];

		case ActionType.GetAgreements:
			return payload;

		default:
			return state;
	}
};
