import React from 'react';
import { IAgreement } from '../../../reducers/agreementReducer';

interface IProps {
	agreement: IAgreement;
}

export const AgreementTermsAndConditions: React.SFC<IProps> = ({ agreement }) => {
	const termsSplitter = /\d{1,2}\./
	const terms = agreement.saleRoiTermsAndConditions ? agreement.saleRoiTermsAndConditions.split(termsSplitter) : null;
	
	if (terms && terms.length > 0) {
		return (
			<div className="agreement-terms-and-conditions">
				<ol>
					{terms && terms.map((x, i) => (
						(x.length > 0) 
						?
						<li key={i}>{x}</li>
						:
						null
					))}
				</ol>
			</div>
		)
	}

	return (
		null
	)
};

// const termsFor = (agreement: IAgreement) => {
// 	return terms2021;

// 	// switch (agreement.salesChannel) {
// 	// 	case 'FSC1: Standard CH -Leasing Co.':
// 	// 	case 'FSB1:Standard CH -Broker':
// 	// 	case 'LRST: Leasing & Rental Special':
// 	// 		return standardContractHireTermsLetter2019;
// 	//
// 	// 	case 'FSC2:Kia Contract Hire*':
// 	// 		return contractHire2019;
// 	//
// 	// 	case 'FSB2:Kia Approved Introducer*':
// 	// 		return approvedIntroducerTerms2019;
// 	//
// 	// 	case 'PSL1:Public Sector Support L1':
// 	// 	case 'PSST:Public Sector Special':
// 	// 		return publicSectorTerms2019;
// 	//
// 	// 	case 'PSL2:Public Sector Support L2':
// 	// 		return publicSectorLevel2Terms2019;
// 	//
// 	// 	case 'FSDR:Daily Rental':
// 	// 	case 'DRL1:Dealer Rental Level 1':
// 	// 		return standardContractHireTermsLetter2019;
// 	//
// 	// 	default:
// 	// 		return standardTerms2019;
// 	// }
// };

// const terms2021 = [
// 	'The support terms detailed are available for an anticipated volume of 1 or more units to be registered before the expiry of this agreement. Failure to achieve this agreed volume will entitle Kia Motors (UK) Ltd to claw back 50% of all support monies paid.',
// 	'The support terms detailed may not be used other than for the purchase and registration of the vehicles for the use of the company (or a named subsidiary) and/or its employees. All vehicles must be registered to the company (or a named subsidiary) and/or its appointed finance company(s) and retained by the company (or named subsidiary) for a minimum of six months following registration, failing this Kia Motors (UK) Ltd will be entitled to claw back 100% of the support monies paid in respect of the vehicles concerned.',
// 	'The support terms detailed may not be used by the Company for the purchase of vehicles on behalf of or with a view to onward sale.',
// 	'Kia Motors (UK) Ltd reserves the right to review support, without prior notice, in line with any future changes in dealer trading margins, manufacturer list prices or changes in market conditions.',
// 	'Supply of vehicles is subject to availability and Kia Motors (UK) Ltd reserves the right to amend the price list and specifications of their vehicles at any time.',
// 	'The support terms detailed are not transferable or to be used in conjunction with any other promotion.',
// 	'All support monies will be ‘off invoice’. It is imperative that the supplying dealer has the correct reference code with each order. Failure to input the correct information when the vehicle is registered will delay payments.',
// 	'All support terms are confidential and should not be disclosed to any third parties.',
// 	'The support terms exclude VAT.',
// 	'Please refer to the terms and conditions of sale of your nominated authorized supplying dealer with regards to vehicle ‘title’.',
// 	'The support terms detailed cover all derivatives of each model in both manual & automatic transmission unless identified separately on our Terms Agreement. Special edition models are excluded unless advised to the contrary.',
// 	'The support terms detailed cover all current models and future minor model year changes only. This does not apply for new models introduced under the same brand model name or trim options. On new vehicle introductions, Kia Motors (UK) Ltd will consider inclusion of support terms to the Agreement and notify you in writing of the support terms available.',
// 	'Kia Motors (UK) Ltd will be entitled to terminate this agreement with immediate effect in the event of a breach of any of these terms and conditions by the Company (or named subsidiary), including the use of the support terms detailed other than for the purchase and registration of vehicles for the use of the Company (or named subsidiary) and/or its employees. In the event of such termination, Kia Motors (UK) Ltd will not be liable to provide the discount support in respect of vehicles already ordered and will not be liable for any loss or additional cost suffered by the company by reason of failure to provide the discount support.',
// 	'The Company will provide access to such information as Kia Motors (UK) Ltd may reasonably require for purposes of monitoring compliance with these terms and conditions during the period of this agreement and for the period of three years after the End Date.',
// 	'Kia Motors (UK) Ltd shall not be liable for any failure to perform its obligations under this agreement which is caused by lack of production capacity or component supplier issues.',
// 	'These terms supersede all previous terms and will be governed by English law and subject to the jurisdiction of the English courts.'
// ];

// const publicSectorTerms2019 = [
// 	'The support terms detailed are available for an anticipated volume of 1 or more units to be registered before the expiry of this agreement. Failure to achieve this agreed volume will entitle Kia Motors UK Ltd to claw back 100% of all support monies paid.',
// 	'The support terms detailed may not be used other than for the purchase and registration of the vehicles for the use of the company (or a named subsidiary) and /or its employees. All vehicles must be registered to the company (or a named subsidiary) and/or its appointed finance company(s) and retained by the company (or named subsidiary) for a minimum of six months following registration, failing this Kia Motors UK Ltd will be entitled to claw back 100% of the support monies paid in respect of the vehicles concerned',
// 	'The support terms detailed may not be used by the Company for the purchase of vehicles on behalf of or with a view to onward sale.',
// 	'Kia Motors UK Ltd reserves the right to review support, without prior notice, in line with any future changes in dealer trading margins, manufacturer list prices or changes in market conditions.',
// 	'Supply of vehicles is subject to availability and Kia Motors UK reserves the right to amend the price list and specifications of their vehicles at any time.',
// 	'The support terms detailed are not transferable or to be used in conjunction with any other promotion.',
// 	'All support monies will be ‘off invoice’. It is imperative that the supplying dealer has the correct reference code with each order. Failure to input the correct information when the vehicle is registered will delay payments.',
// 	'All support terms are confidential and should not be disclosed to any third parties.',
// 	'The support terms exclude VAT.',
// 	'Please refer to the terms and conditions of sale of your nominated authorized supplying dealer with regards to vehicle ‘title’.',
// 	'The support terms detailed cover all derivatives of each model both manual & automatic transmission & petrol & diesel engines excluding any special edition models.',
// 	'The support terms detailed cover all current models and future minor model year changes only. This does not apply for new models introduced under the same brand model name or trim options. On new vehicle introduction, Kia Motors UK Ltd will consider inclusion of support terms to the agreement and notify you in writing of the support terms available.',
// 	'Kia Motors UK Ltd will be entitled to terminate this agreement with immediate effect in the event of a breach of any of these terms and conditions by the Company (or named subsidiary), including the use of the support terms detailed other than for the purchase and registration of vehicles for the use of the Company (or named subsidiary) and/or its employees. I n the event of such termination, Kia Motors UK Ltd will not be liable to provide the discount support in respect of vehicles already ordered and will not be liable for any loss or additional cost suffered by the company by reason of failure to provide the discount support.',
// 	'The Company will provide access to such information as Kia Motors UK Ltd may reasonably require for purposes of monitoring compliance with these terms and conditions during the period of this agreement and for the period of three years after the End Date.',
// 	'Kia Motors UK Ltd shall not be liable for any failure to perform its obligations under this agreement which is caused by lack of production capacity or component supplier issues.',
// 	'These terms supersede all previous terms and will be governed by English law and subject to the jurisdiction of the English courts.'
// ];

// const standardContractHireTermsLetter2019 = [
// 	'The support terms detailed are available for an anticipated volume of 1 or more units to be registered before the expiry of this agreement.  Failure to achieve this agreed volume will entitle Kia Motors UK Ltd to claw back 100% of support monies paid.',
// 	'The support terms detailed may not be used other than for the purchase and registration of vehicles for the use of the Company. All vehicles must be registered to the company and retained by the company or its customer for a minimum of six months following registration. Failing this Kia Motors UK Ltd will be entitled to claw back 100% of the support monies paid in respect of the vehicles concerned. ',
// 	'The support terms detailed may not be used by the Company for the purchase of vehicles on behalf of or with a view to onward sale.',
// 	'The support terms detailed may only be used for vehicles purchased using Contract Hire as a funding method and are not applicable for any other types of finance or outright purchase. Failing this Kia Motors UK Ltd will be entitled to claw back 100% of the support monies paid in respect of the vehicles concerned.',
// 	'Kia Motors UK Ltd reserves the right to review support, without prior notice, in line with any future changes in dealer trading margins, manufacturer list prices or changes in market conditions.',
// 	'Supply of vehicles is subject to availability and Kia Motors UK Ltd reserves the right to amend the price list and specifications of their vehicles at any time.',
// 	'The support terms detailed are not transferable or to be used in conjunction with any other promotion.',
// 	'All support monies will be ‘off invoice’. It is imperative that the supplying dealer has the correct reference code with each order. Failure to input the correct information when the vehicle is registered will delay payments.',
// 	'All support terms are confidential and should not be disclosed to any third parties.',
// 	'The support terms exclude VAT.',
// 	'Please refer to the terms and conditions of sale of your nominated authorized supplying dealer with regards to vehicle ‘title’.',
// 	'The support terms detailed cover all derivatives of each model both manual & automatic transmission & petrol & diesel engines excluding any special edition models.',
// 	'The support terms detailed cover all current models and future minor model year changes only. This does not apply for new models introduced under the same brand model name or trim options. On new vehicle introduction, Kia Motors UK Ltd will consider inclusion of support terms to the agreement and notify you in writing of the support terms available.',
// 	'Kia Motors UK Ltd will be entitled to terminate this agreement with immediate effect in the event of a breach of any of these terms and conditions by the Company or business customer of the Company, including the use of support terms detailed other than for purchase by the Company of vehicles on behalf of and/or for the use of a business customer of the Company and/or its employees. In the event of such termination, Kia Motors UK Ltd will not be liable to provide the discount support in respect of vehicles already ordered and will not be liable for any loss or additional cost suffered by the Company by reason of a failure to provide the discount support.',
// 	'The Company will provide access to such information as Kia Motors UK Ltd may reasonably require for the purposes of monitoring compliance with these terms and conditions during the period of this agreement and for the period of three years after the End Date.',
// 	'Kia Motors UK Ltd shall not be liable for any failure to perform its obligations under this agreement which is caused by lack of production capacity or component supplier issues.',
// 	'These terms supersede all previous terms and will be governed by English law and subject to the jurisdiction of the English courts.'
// ];

// const approvedIntroducerTerms2019 = [
// 	'The support terms detailed are available for an anticipated volume of 1 or more units to be registered before the expiry of this agreement.  Failure to achieve this agreed volume will entitle Kia Motors UK Ltd to claw back 100% of support monies paid.',
// 	'The support terms detailed may not be used other than for the purchase and registration of vehicles for the use of the Company. All vehicles must be registered to the company and retained by the company or its customer for a minimum of six months following registration. Failing this Kia Motors UK Ltd will be entitled to claw back 100% of the support monies paid in respect of the vehicles concerned.',
// 	'The support terms detailed may not be used by the Company for the purchase of vehicles on behalf of or with a view to onward sale.',
// 	'The support terms detailed may only be used for vehicles purchased using Contract Hire as a funding method and are not applicable for any other types of finance or outright purchase. Failing this Kia Motors UK Ltd will be entitled to claw back 100% of the support monies paid in respect of the vehicles concerned.',
// 	'Kia Motors UK Ltd reserves the right to review support, without prior notice, in line with any future changes in dealer trading margins, manufacturer list prices or changes in market conditions.',
// 	'Supply of vehicles is subject to availability and Kia Motors UK Ltd reserves the right to amend the price list and specifications of their vehicles at any time.',
// 	'The support terms detailed are not transferable or to be used in conjunction with any other promotion.',
// 	'All support monies will be ‘off invoice’. It is imperative that the supplying dealer has the correct reference code with each order. Failure to input the correct information when the vehicle is registered will delay payments.',
// 	'All support terms are confidential and should not be disclosed to any third parties.',
// 	'The support terms exclude VAT.',
// 	'Please refer to the terms and conditions of sale of your nominated authorized supplying dealer with regards to vehicle ‘title’.',
// 	'The support terms detailed cover all derivatives of each model both manual & automatic transmission & petrol & diesel engines excluding any special edition models.',
// 	'The support terms detailed cover all current models and future minor model year changes only. This does not apply for new models introduced under the same brand model name or trim options. On new vehicle introduction, Kia Motors UK Ltd will consider inclusion of support terms to the agreement and notify you in writing of the support terms available.',
// 	'Kia Motors UK Ltd will be entitled to terminate this agreement with immediate effect in the event of a breach of any of these terms and conditions by the Company or business customer of the Company, including the use of support terms detailed other than for purchase by the Company of vehicles on behalf of and/or for the use of a business customer of the Company and/or its employees. In the event of such termination, Kia Motors UK Ltd will not be liable to provide the discount support in respect of vehicles already ordered and will not be liable for any loss or additional cost suffered by the Company by reason of a failure to provide the discount support.',
// 	'The Company will provide access to such information as Kia Motors UK Ltd may reasonably require for the purposes of monitoring compliance with these terms and conditions during the period of this agreement and for the period of three years after the End Date.',
// 	'Kia Motors UK Ltd shall not be liable for any failure to perform its obligations under this agreement which is caused by lack of production capacity or component supplier issues.',
// 	'These terms supersede all previous terms and will be governed by English law and subject to the jurisdiction of the English courts.'
// ];

// const contractHire2019 = [
// 	'The support terms detailed are available for an anticipated volume of 1 or more units to be registered before the expiry of this agreement.  Failure to achieve this agreed volume will entitle Kia Motors UK Ltd to claw back 100% of support monies paid.',
// 	'The support terms detailed may not be used other than for the purchase and registration of vehicles for the use of the Company. All vehicles must be registered to the company and retained by the company or its customer for a minimum of six months following registration. Failing this Kia Motors UK Ltd will be entitled to claw back 100% of the support monies paid in respect of the vehicles concerned. ',
// 	'The support terms detailed may not be used by the Company for the purchase of vehicles on behalf of or with a view to onward sale.',
// 	'The support terms detailed may only be used for vehicles purchased using Contract Hire as a funding method and are not applicable for any other types of finance or outright purchase. Failing this Kia Motors UK Ltd will be entitled to claw back 100% of the support monies paid in respect of the vehicles concerned.',
// 	'Kia Motors UK Ltd reserves the right to review support, without prior notice, in line with any future changes in dealer trading margins, manufacturer list prices or changes in market conditions.',
// 	'Supply of vehicles is subject to availability and Kia Motors UK Ltd reserves the right to amend the price list and specifications of their vehicles at any time.',
// 	'The support terms detailed are not transferable or to be used in conjunction with any other promotion.',
// 	'All support monies will be ‘off invoice’. It is imperative that the supplying dealer has the correct reference code with each order. Failure to input the correct information when the vehicle is registered will delay payments.',
// 	'All support terms are confidential and should not be disclosed to any third parties.',
// 	'The support terms exclude VAT.',
// 	'Please refer to the terms and conditions of sale of your nominated authorized supplying dealer with regards to vehicle ‘title’.',
// 	'The support terms detailed cover all derivatives of each model both manual & automatic transmission & petrol & diesel engines excluding any special edition models.',
// 	'The support terms detailed cover all current models and future minor model year changes only. This does not apply for new models introduced under the same brand model name or trim options. On new vehicle introduction, Kia Motors UK Ltd will consider inclusion of support terms to the agreement and notify you in writing of the support terms available.',
// 	'Kia Motors UK Ltd will be entitled to terminate this agreement with immediate effect in the event of a breach of any of these terms and conditions by the Company or business customer of the Company, including the use of support terms detailed other than for purchase by the Company of vehicles on behalf of and/or for the use of a business customer of the Company and/or its employees. In the event of such termination, Kia Motors UK Ltd will not be liable to provide the discount support in respect of vehicles already ordered and will not be liable for any loss or additional cost suffered by the Company by reason of a failure to provide the discount support.',
// 	'The Company will provide access to such information as Kia Motors UK Ltd may reasonably require for the purposes of monitoring compliance with these terms and conditions during the period of this agreement and for the period of three years after the End Date.',
// 	'Kia Motors UK Ltd shall not be liable for any failure to perform its obligations under this agreement which is caused by lack of production capacity or component supplier issues.',
// 	'These terms supersede all previous terms and will be governed by English law and subject to the jurisdiction of the English courts.'
// ];

// const standardTerms2019 = [
// 	'The support terms detailed are available for an anticipated volume of 1 or more units to be registered before the expiry of this agreement. Failure to achieve this agreed volume will entitle Kia Motors UK Ltd to claw back 50% of all support monies paid.',
// 	'The support terms detailed may not be used other than for the purchase and registration of the vehicles for the use of the company (or a named subsidiary) and/or its employees. All vehicles must be registered to the company (or a named subsidiary) and/or its appointed finance company(s) and retained by the company (or named subsidiary) for a minimum of six months following registration, failing this Kia Motors UK Ltd will be entitled to claw back 100% of the support monies paid in respect of the vehicles concerned.',
// 	'The support terms detailed may not be used by the Company for the purchase of vehicles on behalf of or with a view to onward sale.',
// 	'Kia Motors UK Ltd reserves the right to review support, without prior notice, in line with any future changes in dealer trading margins, manufacturer list prices or changes in market conditions.',
// 	'Supply of vehicles is subject to availability and Kia Motors UK reserves the right to amend the price list and specifications of their vehicles at any time.',
// 	'The support terms detailed are not transferable or to be used in conjunction with any other promotion.',
// 	'All support monies will be ‘off invoice’. It is imperative that the supplying dealer has the correct reference code with each order. Failure to input the correct information when the vehicle is registered will delay payments.',
// 	'All support terms are confidential and should not be disclosed to any third parties.',
// 	'The support terms exclude VAT.',
// 	'Please refer to the terms and conditions of sale of your nominated authorized supplying dealer with regards to vehicle ‘title’.',
// 	'The support terms detailed cover all derivatives of each model both manual & automatic transmission & petrol & diesel engines excluding any special edition models.',
// 	'The support terms detailed cover all current models and future minor model year changes only. This does not apply for new models introduced under the same brand model name or trim options. On new vehicle introduction, Kia Motors UK Ltd will consider inclusion of support terms to the agreement and notify you in writing of the support terms available.',
// 	'Kia Motors UK Ltd will be entitled to terminate this agreement with immediate effect in the event of a breach of any of these terms and conditions by the Company (or named subsidiary), including the use of the support terms detailed other than for the purchase and registration of vehicles for the use of the Company (or named subsidiary) and/or its employees. In the event of such termination, Kia Motors UK Ltd will not be liable to provide the discount support in respect of vehicles already ordered and will not be liable for any loss or additional cost suffered by the company by reason of failure to provide the discount support.',
// 	'The Company will provide access to such information as Kia Motors UK Ltd may reasonably require for purposes of monitoring compliance with these terms and conditions during the period of this agreement and for the period of three years after the End Date.',
// 	'Kia Motors UK Ltd shall not be liable for any failure to perform its obligations under this agreement which is caused by lack of production capacity or component supplier issues.',
// 	'These terms supersede all previous terms and will be governed by English law and subject to the jurisdiction of the English courts.'
// ];

// const publicSectorLevel2Terms2019 = [
// 	'The support terms detailed are available for an anticipated volume of 1 or more units to be registered before the expiry of this agreement. Failure to achieve this agreed volume will entitle Kia Motors UK Ltd to claw back 100% of all support monies paid.',
// 	'The support terms detailed may not be used other than for the purchase and registration of the vehicles for the use of the company (or a named subsidiary) and /or its employees. All vehicles must be registered to the company (or a named subsidiary) and/or its appointed finance company(s) and retained by the company (or named subsidiary) for a minimum of six months following registration, failing this Kia Motors UK Ltd will be entitled to claw back 100% of the support monies paid in respect of the vehicles concerned.',
// 	'The support terms detailed may not be used by the Company for the purchase of vehicles on behalf of or with a view to onward sale.',
// 	'Kia Motors UK Ltd reserves the right to review support, without prior notice, in line with any future changes in dealer trading margins, manufacturer list prices or changes in market conditions.',
// 	'Supply of vehicles is subject to availability and Kia Motors UK reserves the right to amend the price list and specifications of their vehicles at any time.',
// 	'The support terms detailed are not transferable or to be used in conjunction with any other promotion.',
// 	'All support monies will be ‘off invoice’. It is imperative that the supplying dealer has the correct reference code with each order. Failure to input the correct information when the vehicle is registered will delay payments.',
// 	'All support terms are confidential and should not be disclosed to any third parties.',
// 	'The support terms exclude VAT.',
// 	'Please refer to the terms and conditions of sale of your nominated authorized supplying dealer with regards to vehicle ‘title’.',
// 	'The support terms detailed cover all derivatives of each model both manual & automatic transmission & petrol & diesel engines excluding any special edition models.',
// 	'The support terms detailed cover all current models and future minor model year changes only. This does not apply for new models introduced under the same brand model name or trim options. On new vehicle introduction, Kia Motors UK Ltd will consider inclusion of support terms to the agreement and notify you in writing of the support terms available.',
// 	'Kia Motors UK Ltd will be entitled to terminate this agreement with immediate effect in the event of a breach of any of these terms and conditions by the Company (or named subsidiary), including the use of the support terms detailed other than for the purchase and registration of vehicles for the use of the Company (or named subsidiary) and/or its employees. I n the event of such termination, Kia Motors UK Ltd will not be liable to provide the discount support in respect of vehicles already ordered and will not be liable for any loss or additional cost suffered by the company by reason of failure to provide the discount support.',
// 	'The Company will provide access to such information as Kia Motors UK Ltd may reasonably require for purposes of monitoring compliance with these terms and conditions during the period of this agreement and for the period of three years after the End Date.',
// 	'Kia Motors UK Ltd shall not be liable for any failure to perform its obligations under this agreement which is caused by lack of production capacity or component supplier issues.',
// 	'These terms supersede all previous terms and will be governed by English law and subject to the jurisdiction of the English courts.'
// ];
