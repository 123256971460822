import { ActionType } from './types';
import { IReduxState } from './../reducers/index';
import { Dispatch } from 'redux';
import { Api, IApi } from './api';
import { IUser } from '../reducers/authReducer';

export const updateUser = (user: IUser, setSubmitting: (b: boolean) => void) => async (
	dispatch: Dispatch,
	getState: () => IReduxState
) => {
	dispatch({ type: ActionType.Loading, payload: { updateUser: true } });
	dispatch({ type: ActionType.Error, payload: { updateUser: undefined } });

	const api: IApi = new Api(getState);
	try {
		const response = await api.post('/user', user);
		const updatedUser: IUser = response.data;
		dispatch({ type: ActionType.GetUser, payload: { user: updatedUser, persist: !!localStorage.getItem('auth') } });
		setSubmitting(false);
	} catch (err) {
		console.error(err);
		api.put('/log', { contents: `CLIENT Unable to update user demo: ${JSON.stringify(err)}` });
	} finally {
		dispatch({ type: ActionType.Loading, payload: { updateUser: false } });
		setSubmitting(false);
	}
};
