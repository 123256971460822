import React from 'react';

function Errors({ errors }: { errors: Array<string | undefined> }) {
    if (errors.length === 0) { return null }
    return (
        <div className="message is-danger">
            <div className="message-body">
                <ul>
                    {errors.map(error => <li>&middot; {error}</li>)}
                </ul>
            </div>
        </div>
    );
}

export default Errors;