import React from 'react';
import { IAgreement } from '../../../reducers/agreementReducer';

interface IProps {
	agreement: IAgreement;
}

export const SupportSuffix: React.SFC<IProps> = ({ agreement }) => {
	switch (agreement.salesChannel) {
		case 'FSC1: Standard CH -Leasing Co.':
		case 'FSB1:Standard CH -Broker':
		case 'LRST: Leasing & Rental Special':
			return <>{''}</>;

		case 'FSC2:Kia Contract Hire*':
			return <strong className="desktop-only"> (incl. 3% dealer margin)</strong>;

		case 'FSB2:Kia Approved Introducer*':
			return <strong className="desktop-only"> (incl. 3% dealer margin)</strong>;

		case 'PSL1:Public Sector Support L1':
		case 'PSST:Public Sector Special':
			return (
				<span className="desktop-only">
					{' '}
					& free of charge metallic paint <strong>(incl. 3% dealer margin)</strong>
				</span>
			);

		case 'PSL2:Public Sector Support L2':
			return (
				<span className="desktop-only">
					{' '}
					& free of charge metallic paint <strong>(incl. 3% dealer margin)</strong>
				</span>
			);

		case 'FSDR:Daily Rental':
		case 'DRL1:Dealer Rental Level 1':
			return <>{''}</>;

		default:
			return <>{''}</>;
	}
};
