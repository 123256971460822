import { IAction, ActionType } from '../actions/types';

export interface IErrorState {
	login?: string;
	changePassword?: string[];
	mustChangePassword?: string[];
	resetPassword?: string;
}

const initialState = {
	login: undefined,
	changePassword: undefined,
	mustChangePassword: undefined,
	resetPassword: undefined
};

export const errorReducer = (state: IErrorState = initialState, { type, payload }: IAction) => {
	switch (type) {
		case ActionType.Error:
			return { ...state, ...payload };

		default:
			return state;
	}
};
