import { IAction, ActionType } from '../actions/types';

export interface IDemo {
	id: string;
	model: string;
	driverFirstName: string;
	driverLastName: string;
	start: Date;
	end: Date;
	status: string;
}

export const demoReducer = (state: IDemo[] = [], { type, payload }: IAction) => {
	switch (type) {
		case ActionType.RequestDemo:
			return [...state, payload];

		case ActionType.GetDemos:
			return payload;

		default:
			return state;
	}
};
