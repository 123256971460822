import { combineReducers, Reducer } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { loadingReducer, ILoadingState } from './loadingReducer';
import { agreementReducer, IAgreement } from './agreementReducer';
import { authReducer, IAuthState } from './authReducer';
import { demoModelReducer, IModelInfo } from './demoModelReducer';
import { demoReducer, IDemo } from './demoReducer';
import { errorReducer, IErrorState } from './errorReducer';
import { fleetProfileReducer, IFleetProfile } from './fleetProfileReducer';
import { IAction } from '../actions/types';

export interface IReduxState {
	agreements: IAgreement[];
	auth: IAuthState;
	demos: IDemo[];
	demoModels: IModelInfo[];
	errors: IErrorState;
	fleetProfile: IFleetProfile;
	loading: ILoadingState;
	toastr: any;
}

export const rootReducer: Reducer<IReduxState, IAction> = combineReducers({
	agreements: agreementReducer,
	auth: authReducer,
	demoModels: demoModelReducer,
	demos: demoReducer,
	errors: errorReducer,
	fleetProfile: fleetProfileReducer,
	loading: loadingReducer,
	toastr: toastrReducer
});
