import React from 'react';
import { connect } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import { FormikCheckbox } from '../common/forms/FormikCheckbox';
import { IReduxState } from '../../reducers';
import { updateUser } from '../../actions/userActions';
import { IUser } from '../../reducers/authReducer';

interface IFormValues {
	gdprTelephone: boolean;
	gdprSms: boolean;
	gdprEmail: boolean;
	gdprDirectMail: boolean;
}

interface IProps {
	user?: IUser;
	updateUser: any;
}

const BaseMarketingPreferenceForm: React.SFC<IProps> = ({ user, updateUser }) => {
	const initialValues: IFormValues = {
		gdprTelephone: (user && user.gdprTelephone) || false,
		gdprSms: (user && user.gdprSms) || false,
		gdprEmail: (user && user.gdprEmail) || false,
		gdprDirectMail: (user && user.gdprDirectMail) || false
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(formValues: IFormValues, { setSubmitting }) => {
				updateUser(
					{
						...user,
						...formValues
					},
					setSubmitting
				);
			}}
			render={(formikProps: FormikProps<IFormValues>) => (
				<form
					className="MarketingPreferenceForm"
					onSubmit={e => {
						console.log(e);
						formikProps.handleSubmit(e);
					}}
				>
					<FormikCheckbox label="Telephone" name="gdprTelephone" />
					<FormikCheckbox label="SMS" name="gdprSms" />
					<FormikCheckbox label="Email" name="gdprEmail" />
					<FormikCheckbox label="Direct Mail" name="gdprDirectMail" />
					<button type="submit" className={`button  ${formikProps.isSubmitting ? 'is-loading' : ''}`}>
						Save Preferences
					</button>
				</form>
			)}
		/>
	);
};

const mapStateToProps = (state: IReduxState) => ({
	user: state.auth.user
});

export const MarketingPreferenceForm = connect(
	mapStateToProps,
	{ updateUser }
)(BaseMarketingPreferenceForm);
