import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { Formik, FormikProps } from 'formik';
import { FormikInput } from '../common/forms/FormikInput';
import * as Yup from 'yup';
import { resetPassword } from '../../actions/authActions';
import { IReduxState } from '../../reducers';

interface IFormValues {
	email: string;
	[key: string]: string;
}

const validationSchema = Yup.object().shape({
	email: Yup.string()
		.email("This doesn't look like a valid email")
		.required('Email is required')
});

const initialValues: IFormValues = {
	email: ''
};

interface IProps extends RouteComponentProps {
	loading: boolean;
	error?: string;
	resetPassword: any;
}

const BaseForgotPasswordForm: React.SFC<IProps> = ({ resetPassword, history, error: serverError, loading }) => (
	<Formik
		initialValues={initialValues}
		validationSchema={validationSchema}
		onSubmit={({ email }: IFormValues, { resetForm }) => {
			resetPassword(email, history, toastr);
			resetForm();
		}}
		render={(formikProps: FormikProps<IFormValues>) => {
			const formikErrors = Object.keys(formikProps.touched)
				.map(x => formikProps.errors[x])
				.filter(x => x);
			const errors = [...formikErrors, serverError].filter(x => x);

			return (
				<form onSubmit={formikProps.handleSubmit}>
					<FormikInput
						type="email"
						placeholder="john.doe@your-company.co.uk"
						name="email"
						icon="user"
						iconPosition="left"
					/>
					{errors.length > 0 && (
						<div className="message is-danger">
							<div className="message-body">
								<ul>
									{errors.map(x => (
										<li key={x}>{x}</li>
									))}
								</ul>
							</div>
						</div>
					)}
					<div className="field">
						<div className="control">
							<button className={`button is-primary is-fullwidth ${formikProps.isSubmitting ? 'is-loading' : ''}`}>
								Send me a reset link
							</button>
						</div>
					</div>
				</form>
			);
		}}
	/>
);

const mapStateToProps = (state: IReduxState) => ({
	loading: state.loading.resetPassword,
	error: state.errors.resetPassword
});

export const ForgotPasswordForm = connect(
	mapStateToProps,
	{ resetPassword }
)(withRouter(BaseForgotPasswordForm));
