import { ToastrEmitter, toastr } from 'react-redux-toastr';
import { Api, IApi } from './api';
import { Dispatch } from 'redux';
import { ActionType } from './types';
import { IReduxState } from '../reducers';
import { IAgreement } from '../reducers/agreementReducer';
import { History } from 'history';

export const getAgreements = () => async (dispatch: Dispatch, getState: () => IReduxState) => {
	dispatch({ type: ActionType.Loading, payload: { agreements: true } });
	const api: IApi = new Api(getState);
	try {
		const res = await api.get('/agreement');

		const agreements: IAgreement[] = res.data.map(parseAgreement);

		const twoYearsAgo = new Date(Date.now() - 1000 * 60 * 60 * 24 * 365 * 2);
		const lastTwoYearsOnly: IAgreement[] = agreements.filter(x => x.end >= twoYearsAgo);

		dispatch({ type: ActionType.GetAgreements, payload: lastTwoYearsOnly });
	} catch (err) {
		console.error(err);
		api.put('/log', { contents: `CLIENT Unable to get agreements: ${JSON.stringify(err)}` });
	} finally {
		dispatch({ type: ActionType.Loading, payload: { agreements: false } });
	}
};

export const getAgreement = (id: string) => async (dispatch: Dispatch, getState: () => IReduxState) => {
	dispatch({ type: ActionType.Loading, payload: { agreements: true } });
	const api: IApi = new Api(getState);
	try {
		const res = await api.get(`/agreement/${id}`);
		const agreement: IAgreement = parseAgreement(res.data);

		dispatch({ type: ActionType.GetAgreement, payload: agreement });
	} catch (err) {
		console.error(err);
		api.put('/log', { contents: `CLIENT Unable to get agreement #${id}: ${JSON.stringify(err)}` });
	} finally {
		dispatch({ type: ActionType.Loading, payload: { agreements: false } });
	}
};

export const downloadLetter = (id: string) => async (dispatch: Dispatch, getState: () => IReduxState) => {
	dispatch({ type: ActionType.Loading, payload: { downloadAgreement: true } });

	let base64pdf;
	const api: IApi = new Api(getState);
	try {
		const response = await api.get(`/agreement/${id}/letter`);
		base64pdf = response.data;
	} catch (err) {
		console.error(err);
		toastr.error(
			'Unable to generate PDF',
			"Sorry, we couldn't download the data from the server. Please log out and back in before retrying."
		);
		api.put('/log', { contents: `CLIENT Unable to download agreement #${id}: ${JSON.stringify(err)}` });
		dispatch({ type: ActionType.Loading, payload: { downloadAgreement: false } });
		return;
	}

	try {
		// Standard code for Chrome, FF, and Edge
		const url = `data:application/pdf;base64,${base64pdf}`;
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'Agreement.pdf');
		link.style.display = 'none';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	} catch (err) {
		if (err.description && err.description.startsWith('The data area passed to a system call is too small.')) {
			try {
				// This is just for internet explorer
				const api: IApi = new Api(getState);
				const response = await api.get(`/agreement/${id}/letter`);
				const blob = base64toBlob(response.data, 'application/pdf');
				window.navigator.msSaveOrOpenBlob(blob, 'Agreement.pdf');
			} catch (err2) {
				console.error(err2);
				toastr.error(
					'Unable to generate PDF',
					"Sorry. It looks like you're using an older browser. We're trying to add support. Until then, please use Firefox or Chrome."
				);
			}
		} else {
			console.error(err);
			api.put('/log', { contents: `CLIENT Unable to download agreement #${id}: ${JSON.stringify(err)}` });
			toastr.error(
				'Unable to generate PDF',
				'Sorry, something went wrong. Please log out and back in before retrying.'
			);
		}
	} finally {
		dispatch({ type: ActionType.Loading, payload: { downloadAgreement: false } });
	}
};

const base64toBlob = (base64Data: any, contentType: any) => {
	contentType = contentType || '';
	const sliceSize = 1024;
	const byteCharacters = atob(base64Data);
	const bytesLength = byteCharacters.length;
	const slicesCount = Math.ceil(bytesLength / sliceSize);
	const byteArrays = new Array(slicesCount);

	for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
		const begin = sliceIndex * sliceSize;
		const end = Math.min(begin + sliceSize, bytesLength);

		const bytes = new Array(end - begin);
		for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
			bytes[i] = byteCharacters[offset].charCodeAt(0);
		}
		byteArrays[sliceIndex] = new Uint8Array(bytes);
	}
	return new Blob(byteArrays, { type: contentType });
};

export const agreeTerms = (id: string, toastr: ToastrEmitter, history: History) => async (
	dispatch: Dispatch,
	getState: () => IReduxState
) => {
	dispatch({ type: ActionType.Loading, payload: { agreeTerms: true } });
	const api: IApi = new Api(getState);
	try {
		const response = await api.post(`/agreement/${id}/approve`, {});
		const updatedAgreement: IAgreement = parseAgreement(response.data);

		const currentAgreement: IAgreement | undefined = getState().agreements.find(x => x.id === id);
		if (currentAgreement) {
			dispatch({
				type: ActionType.GetAgreement,
				payload: updatedAgreement
			});
		}

		toastr.success('Agreement approved', `You have agreed the terms`);
	} catch (err) {
		console.error(err);
		api.put('/log', { contents: `CLIENT Unable to agree agreement #${id}: ${JSON.stringify(err)}` });
	} finally {
		dispatch({ type: ActionType.Loading, payload: { agreeTerms: false } });
	}
};

function parseAgreement(apiAgreement: any): IAgreement {
	return {
		...apiAgreement,

		start: new Date(apiAgreement.start),
		end: new Date(apiAgreement.end),
		cxAcknowledgementDate: !!apiAgreement.cxAcknowledgementDate
			? new Date(apiAgreement.cxAcknowledgementDate)
			: undefined
	};
}
