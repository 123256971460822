import { ToastrEmitter } from 'react-redux-toastr';
import { IReduxState } from './../reducers/index';
import { Api, IApi } from './api';
import axios from 'axios';
import { Dispatch } from 'redux';
import { ActionType } from './types';
import { History } from 'history';
import * as Routes from '../routes';
import { IJwt, IUser } from '../reducers/authReducer';
import apiEndpoint from './api';

const rootUrl = apiEndpoint;

export const login = (emailAddress: string, password: string, rememberMe: boolean, history: History) => async (
	dispatch: Dispatch
) => {
	dispatch({ type: ActionType.Loading, payload: { login: true } });
	dispatch({ type: ActionType.Error, payload: { login: undefined } });

	try {
		const tokenUrl = `${rootUrl}/auth/token`;
		const getTokenResponse = await axios.post(tokenUrl, { emailAddress, password });
		const jwt: IJwt = getTokenResponse.data;

		dispatch({
			type: ActionType.GetToken,
			payload: {
				jwt: {
					expires: new Date(jwt.expires),
					token: jwt.token
				},
				persist: rememberMe
			}
		});

		const userUrl = `${rootUrl}/user`;
		const userResponse = await axios.get(userUrl, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${jwt.token}`
			}
		});

		const user: IUser = userResponse.data;

		dispatch({
			type: ActionType.GetUser,
			payload: {
				user,
				persist: rememberMe
			}
		});

		history.push(Routes.DASHBOARD);
	} catch (err) {
		console.error(err);
		if (err.response.status !== 401) {
			axios.put(`${rootUrl}/log`, { contents: `CLIENT Unable to login: ${JSON.stringify(err)}` });
		}
		dispatch({
			type: ActionType.Error,
			payload: {
				login:
					err.response.status === 401
						? 'Invalid email/password combination'
						: 'Sorry, an unexpected error occured.'
			}
		});
	} finally {
		dispatch({
			type: ActionType.Loading,
			payload: { login: false }
		});
	}
};

export const logout = (history: History) => async (dispatch: Dispatch, getState: () => IReduxState) => {
	try {
		const api: IApi = new Api(getState);
		await api.delete('/auth', {});
	} catch (err) {
		console.error(err);
		axios.put(`${rootUrl}/log`, { contents: `CLIENT Unable to logout: ${JSON.stringify(err)}` });
	} finally {
		dispatch({ type: ActionType.Logout });
		if (history) {
			history.push(Routes.LOGIN);
		}
	}
};

export const changePasswordWithConfirm = (
	oldPassword: string,
	newPassword: string,
	history: History,
	toastr: ToastrEmitter
) => async (dispatch: Dispatch, getState: () => IReduxState) => {
	dispatch({ type: ActionType.Loading, payload: { changePassword: true } });
	dispatch({ type: ActionType.Error, payload: { changePassword: undefined } });

	try {
		const user = getState().auth.user;

		if (!user) {
			toastr.error('No User Information', 'Unable to find your email address. Please log in again.');
			history.push(Routes.LOGIN);
			return;
		}

		const api: IApi = new Api(getState);
		await api.post('/auth/changePassword', { oldPassword, newPassword });
		toastr.success('Password updated.', 'You can log in with your new password in future.');
	} catch (err) {
		dispatch({ type: ActionType.Error, payload: { changePassword: ['Old password incorrect.'] } });
		return;
	} finally {
		dispatch({ type: ActionType.Loading, payload: { changePassword: false } });
	}
};

export const changePassword = (oldPassword: string, newPassword: string, history: History) => async (
	dispatch: Dispatch,
	getState: () => IReduxState
) => {
	dispatch({ type: ActionType.Loading, payload: { mustChangePassword: true } });
	dispatch({ type: ActionType.Error, payload: { mustChangePassword: undefined } });

	try {
		const api: IApi = new Api(getState);

		const data = {
			oldPassword,
			newPassword
		};

		await api.post('/auth/changePassword', data);

		history.push(Routes.DASHBOARD);
	} catch (err) {
		const errData = err.response.data;

		const errors: string[] = Object.keys(errData).reduce(
			(acc: string[], next: string) => [...acc, ...errData[next]],
			[]
		);

		dispatch({
			type: ActionType.Error,
			payload: { mustChangePassword: errors }
		});
	} finally {
		dispatch({
			type: ActionType.Loading,
			payload: { mustChangePassword: false }
		});
	}
};

export const resetPassword = (emailAddress: string, history: History, toastr: ToastrEmitter) => async (
	dispatch: Dispatch,
	getState: () => IReduxState
) => {
	dispatch({ type: ActionType.Loading, payload: { resetPassword: true } });
	dispatch({ type: ActionType.Error, payload: { resetPassword: undefined } });

	try {
		const api: IApi = new Api(getState);

		const returnUrl = `${window.location.origin}${Routes.RESET_PASSWORD}`;
		await api.post('/auth/forgot', { emailAddress, returnUrl });

		history.push(Routes.LOGIN);
		toastr.success('Reset Link Sent', 'Follow the link on your email to reset your password');
	} catch (err) {
		console.log(err);
		dispatch({
			type: ActionType.Error,
			payload: { resetPassword: "Sorry, we can't find that email. Please check and try again." }
		});
	} finally {
		dispatch({ type: ActionType.Loading, payload: { resetPassword: false } });
	}
};

export const changePasswordWithToken = (
	token: string,
	newPassword: string,
	history: History,
	toastr: ToastrEmitter
) => async (dispatch: Dispatch, getState: () => IReduxState) => {
	dispatch({ type: ActionType.Loading, payload: { changePasswordWithToken: true } });
	dispatch({ type: ActionType.Error, payload: { changePasswordWithToken: undefined } });

	try {
		await axios.post(`${rootUrl}/auth/changePasswordWithToken`, {
			token,
			newPassword
		});
		history.push(Routes.LOGIN);
		toastr.success('Password Reset Successfully', 'You can now log in with your new password');
	} catch (err) {
		console.error(err);
		// todo feed back error to user
	} finally {
		dispatch({ type: ActionType.Loading, payload: { changePasswordWithToken: false } });
	}
};
