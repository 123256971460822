import React from 'react';
import { connect } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import { toastr } from 'react-redux-toastr';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { FormikInput } from '../common/forms/FormikInput';
import { IReduxState } from '../../reducers';
import { changePasswordWithConfirm } from '../../actions/authActions';

const validationSchema = Yup.object().shape({
	oldPassword: Yup.string().required('Old password is required'),
	newPassword: Yup.string()
		.required('You must enter a new password')
		.min(8, 'New password must be at least 8 characters long'),
	confirmPassword: Yup.string().oneOf([Yup.ref('newPassword')], 'New and confirm passwords do not match')
});

interface IFormValues {
	oldPassword: string;
	newPassword: string;
	confirmPassword: string;
	[key: string]: string;
}

const initialValues: IFormValues = {
	oldPassword: '',
	newPassword: '',
	confirmPassword: ''
};

interface IProps extends RouteComponentProps {
	changePasswordWithConfirm: any;
	errors?: string[];
	loading: boolean;
}

const BaseChangePasswordForm: React.SFC<IProps> = ({
	changePasswordWithConfirm,
	history,
	loading,
	errors: serverErrors
}) => (
	<Formik
		initialValues={initialValues}
		validationSchema={validationSchema}
		onSubmit={({ oldPassword, newPassword }: IFormValues, { resetForm }) => {
			changePasswordWithConfirm(oldPassword, newPassword, history, toastr);
			resetForm();
		}}
		render={(formikProps: FormikProps<IFormValues>) => {
			const errors = [
				...Object.keys(formikProps.touched).map(x => formikProps.errors[x]),
				...(serverErrors || [])
			].filter(x => x);
			return (
				<form onSubmit={formikProps.handleSubmit}>
					<FormikInput
						type="password"
						placeholder="Your current password"
						name="oldPassword"
						label="Old Password"
						icon="lock"
						iconPosition="left"
					/>
					<FormikInput
						type="password"
						placeholder="Should be at least 8 characters"
						name="newPassword"
						label="New Password"
						icon="lock"
						iconPosition="left"
					/>
					<FormikInput
						type="password"
						placeholder="Enter again to prevent typos"
						name="confirmPassword"
						label="Confirm New Password"
						icon="lock"
						iconPosition="left"
					/>
					{errors.length > 0 && (
						<div className="message is-danger">
							<div className="message-body">
								<p>Unable to change the password:</p>
								<ul>
									{errors.map(x => (
										<li key={x}>{x}</li>
									))}
								</ul>
							</div>
						</div>
					)}
					<div className="field">
						<div className="control">
							<button
								className={`button ${loading ? 'is-loading' : ''}`}
								type="submit"
								disabled={loading}
							>
								Change Password
							</button>
						</div>
					</div>
				</form>
			);
		}}
	/>
);

const mapStateToProps = (state: IReduxState) => ({
	loading: state.loading.changePassword,
	errors: state.errors.changePassword
});

export const ChangePasswordForm = connect(
	mapStateToProps,
	{ changePasswordWithConfirm }
)(withRouter(BaseChangePasswordForm));
