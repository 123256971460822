import React from 'react';
import { Link } from 'react-router-dom';
import { LoginForm } from './LoginForm';
import * as Routes from '../../routes';
import { PageWithNavigation } from '../common/Navigation/PageWithNavigation';

export const LoginPage: React.SFC = () => (
	<PageWithNavigation>
		<div className="login-page">
			<div>
				<div className="login-image" />
				<div className="login-form-holder">
					<div>
						<h2>Log in to Kia Terms Portal</h2>
						<LoginForm />
						<Link className="forgot-link is-pulled-right" to={Routes.FORGOT_PASSWORD}>
							Forgot your password?
						</Link>
					</div>
				</div>
			</div>
		</div>
	</PageWithNavigation>
);
