import React from 'react';
import { Switch, Route } from 'react-router-dom';

import * as Routes from './routes';
import PrivateRoute from './components/common/PrivateRoute';

import { LoginPage } from './components/login/LoginPage';
import { LogoutPage } from './components/logout/LogoutPage';
import { ResetPasswordPage } from './components/reset/ResetPasswordPage';
import { DashboardPage } from './components/dash/DashboardPage';
import { FourOhFourPage } from './components/FourOhFourPage';
import { SettingsPage } from './components/settings/SettingsPage';
import { ForgotPasswordPage } from './components/forgotPassword/ForgotPasswordPage';
import { RequestDemoPage } from './components/demos/RequestDemoPage';
import { DemoListPage } from './components/demos/DemoListPage';
import { MustChangePasswordPage } from './components/login/MustChangePasswordPage';
import { AgreementPage } from './components/agreements/AgreementPage';
import { AgreementsListPage } from './components/agreements/AgreementsListPage';
import { ContactPage } from './components/contact/ContactPage';
import { SetupAccountPage } from './components/setupAccount/SetupAccountPage';

export const App: React.SFC = () => (
	<Switch>
		<Route exact path={Routes.LOGIN} component={LoginPage} />
		<Route exact path={Routes.LOGOUT} component={LogoutPage} />
		<Route exact path={Routes.FORGOT_PASSWORD} component={ForgotPasswordPage} />
		<Route exact path={Routes.RESET_PASSWORD} component={ResetPasswordPage} />
		<Route exact path={Routes.SETUP_ACCOUNT} component={SetupAccountPage} />
		<Route exact path={Routes.SETUP_ACCOUNT_REDIRECT} component={SetupAccountPage} />
		<Route exact path={Routes.CONTACT} component={ContactPage} />
		<PrivateRoute exact path={Routes.DASHBOARD} component={DashboardPage} />
		<PrivateRoute exact path={Routes.SETTINGS} component={SettingsPage} />
		<PrivateRoute exact path={Routes.REQUEST_DEMO} component={RequestDemoPage} />
		<PrivateRoute exact path={Routes.DEMOS} component={DemoListPage} />
		<PrivateRoute exact path={Routes.MUST_CHANGE_PASSWORD} component={MustChangePasswordPage} />
		<PrivateRoute exact path={Routes.AGREEMENT} component={AgreementPage} />
		<PrivateRoute exact path={Routes.AGREEMENTS} component={AgreementsListPage} />
		<PrivateRoute exact path={'/'} component={DashboardPage} />
		<Route path={'/'} component={FourOhFourPage} />
	</Switch>
);
