import React from 'react';
import { Field as FormikField, FieldProps } from 'formik';

interface IProps {
	label?: string;
	name: string;
	icon?: string;
	placeholder?: string;
	iconPosition?: 'left' | undefined;
	type?: string;
	style?: {};
	disabled?: boolean;
}

export const FormikInput: React.SFC<IProps> = ({ label, name, type, placeholder, style, disabled = false }: IProps) => (
	<FormikField
		render={({ form: { values, setFieldValue } }: FieldProps) => {
			return (
				<div className="field" style={style || {}}>
					{label && <label className="label">{label}</label>}
					<div className="control">
						<input
							className="input"
							type={type || 'text'}
							placeholder={placeholder}
							value={values[name]}
							onChange={({ target: { value } }) => setFieldValue(name, value)}
							disabled={disabled}
						/>
					</div>
				</div>
			);
		}}
	/>
);
