// Polyfills should be first
import 'react-app-polyfill/ie11';
import 'core-js';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';

import { App } from './App';

import reduxStore from './reduxStore';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'animate.css/animate.min.css';
import './scss/app.scss';

ReactDOM.render(
	<Provider store={reduxStore}>
		<BrowserRouter>
			<>
				<App />
				<ReduxToastr
					timeOut={2000}
					newestOnTop={false}
					preventDuplicates
					position="top-left"
					transitionIn="fadeIn"
					transitionOut="fadeOut"
					closeOnToastrClick
				/>
			</>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);
