import { IAction, ActionType } from '../actions/types';

export interface IModelFact {
	icon: string;
	text: string;
}

export interface IModelInfo {
	order: number;
	name: string;
	allowRequests: boolean;
	thumbnail: string;
	otherNames: string[];
	facts: IModelFact[];
}

export const demoModelReducer = (state: IModelInfo[] = [], { type, payload }: IAction): IModelInfo[] => {
	switch (type) {
		case ActionType.GetDemoModels:
			return payload;

		default:
			return state;
	}
};
