import React from 'react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { FormikDropdown, IDropdownOption } from '../../common/forms/FormikDropdown';
import { FormikInput } from '../../common/forms/FormikInput';

export interface IDriverDetails {
	salutation?: 'Mr' | 'Miss' | 'Mrs' | 'Ms';
	firstName: string;
	lastName: string;
	email: string;
	mobile: string;
}

const initialValues: IDriverDetails = {
	salutation: undefined,
	firstName: '',
	lastName: '',
	email: '',
	mobile: ''
};

const validationSchema: Yup.ObjectSchema<any> = Yup.object().shape({
	salutation: Yup.string()
		.oneOf(['Mr', 'Miss', 'Mrs', 'Ms'])
		.required('required'),
	firstName: Yup.string().required('required'),
	lastName: Yup.string().required('required'),
	email: Yup.string().required('required'),
	mobile: Yup.string().required('required')
});

const salutationOptions: IDropdownOption[] = [
	{ text: 'Mr', value: 'Mr' },
	{ text: 'Miss', value: 'Miss' },
	{ text: 'Mrs', value: 'Mrs' },
	{ text: 'Ms', value: 'Ms' }
];

interface IProps {
	onSubmit: (form: IDriverDetails) => void;
	onBack: () => void;
	transmissionSelected: boolean;
}

export const DemoDriverForm: React.SFC<IProps> = ({ onSubmit, onBack, transmissionSelected }) => (
	<Formik
		initialValues={initialValues}
		validationSchema={validationSchema}
		onSubmit={(formValues: IDriverDetails) => onSubmit(formValues)}
		render={(formikProps: FormikProps<IDriverDetails>) => {
			const { email, firstName, lastName, mobile, salutation } = formikProps.values;
			const fieldsComplete = email && firstName && lastName && mobile && salutation;
			return (
				<form onSubmit={formikProps.handleSubmit}>
					<div className="columns">
						<div className="column">
							<FormikDropdown
								name="salutation"
								label="Driver Salutation"
								options={salutationOptions}
								placeholder="Choose"
							/>
						</div>
						<div className="column">
							<FormikInput name="firstName" label="Driver First Name" placeholder="John" />
						</div>
						<div className="column">
							<FormikInput name="lastName" label="Driver Last Name" placeholder="Doe" />
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<FormikInput name="email" label="Driver Email" placeholder="john.doe@your-company.com" />
						</div>
						<div className="column">
							<FormikInput name="mobile" label="Driver Mobile Phone" placeholder="0789 123 4567" />
						</div>
					</div>
					<p style={{ maxWidth: 560, margin: 'auto', textAlign: 'center', marginBottom: '1rem' }}>
						Once you request a demo, someone will be in touch to arrange a vehicle for you.
					</p>
					<button className="button" onClick={onBack}>
						Back
					</button>
					<button
						className={`button is-primary is-pulled-right ${
							formikProps.isSubmitting ? 'is-loading is-disabled' : ''
						}`}
						type="submit"
						disabled={!transmissionSelected || !fieldsComplete}
					>
						Request
					</button>
				</form>
			);
		}}
	/>
);
