import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AgreementsTable } from './AgreementsTable';
import { getAgreements } from '../../actions/agreementActions';
import { IReduxState } from '../../reducers';
import { IAgreement } from '../../reducers/agreementReducer';

interface IProps {
	getAgreements: any;
	agreements: IAgreement[];
}

const BaseAgreementsListWidget: React.SFC<IProps> = ({ agreements, getAgreements }) => {
	const [currentTab, setCurrentTab] = useState('Active');
	const changeTab = () => setCurrentTab(currentTab === 'Active' ? 'Archived' : 'Active');

	useEffect(
		() => {
			getAgreements();
		},
		[getAgreements]
	);

	const ucn = agreements && agreements.length ? agreements[0].ucn : '';

	return (
		<div className="box agreement-list-widget">
			<h3 className="is-size-4 has-text-weight-bold">Your Agreements</h3>
			<p style={{ marginTop: '0.5em', marginBottom: '1.5em' }}>
				UCN: <strong>{ucn}</strong>
			</p>
			<div className="tabs is-boxed">
				<ul>
					<li className={currentTab === 'Active' ? 'is-active' : ''}>
						{/*eslint-disable-next-line*/}
						<a onClick={changeTab}>Active</a>
					</li>
					<li className={currentTab === 'Archived' ? 'is-active' : ''}>
						{/*eslint-disable-next-line*/}
						<a onClick={changeTab}>Archived</a>
					</li>
				</ul>
			</div>
			<AgreementsTable
				rows={agreements.filter(x => {
					// Filter for approved and non-approved
					if (currentTab === 'Active') {
						return x.status === 'Approved';
					} else {
						return x.status !== 'Approved';
					}
				})}
			/>
		</div>
	);
};

const mapStateToProps = (state: IReduxState) => ({
	agreements: state.agreements
});

export const AgreementsListWidget = connect(
	mapStateToProps,
	{ getAgreements }
)(BaseAgreementsListWidget);
