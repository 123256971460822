import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PageWithNavigation } from '../common/Navigation/PageWithNavigation';
import { IReduxState } from '../../reducers';
import { IUser } from '../../reducers/authReducer';

interface IProps {
	user?: IUser;
}

const ContactPageBase: React.SFC<IProps> = ({ user }) => {
	useEffect(
		() => {
			const w: any = window;
			const { fcWidget } = w;

			fcWidget.init({
				token: '750ae888-bbcf-4e4c-ba0e-03c61d31bc3c',
				host: 'https://wchat.freshchat.com',
				config:{
					headerProperty:{
						backgroundColor:"#05141f"
					}
			}
			});

			if (user) {
				fcWidget.user.setEmail(user.emailAddress);
				fcWidget.user.setProperties({
					company: user.companyName
				});
			}

			return () => fcWidget.destroy();
		},
		[user]
	);

	return (
		<PageWithNavigation>
			<div className="container" style={{ marginTop: 20 }}>
				<div className="box">
					<h3 className="is-size-4 has-text-weight-bold">Contact Kia Business</h3>
					<hr />
					<p style={{ marginBottom: 20 }}>
						For product information please visit us at{' '}
						<a href="https://www.kia.com/uk/business/range" target="_blank" rel="noopener noreferrer">
							kia.com/uk/business/range
						</a>
					</p>
					<p style={{ marginBottom: 20 }}>
						Please contact the Kia Business Centre via telephone or e-mail for any queries or to request any
						amendments to your personal or fleet details.
					</p>
					<p style={{ marginBottom: 20 }}>
						Email: <a href="mailto:enquiries@kiabusinesscentre.co.uk">enquiries@kiabusinesscentre.co.uk</a>
						<br />
						Call: <a href="tel:01932 283945">01932 283945</a>
					</p>
					<p style={{ marginBottom: 20 }}>
						<b>National Busines Sales Manager</b> - Andy Dunsdon
						<br />
						<b>Regional Business Sales Manager, Scotland &amp; NI </b> - Lorraine Fellowes
						<br />
						<b>Regional Business Sales Manager, North </b> - Kevin Hancock
						<br />
						<b>Regional Business Sales Manager, East</b> - Adrian Freeman
						<br />
						<b>Regional Business Sales Manager, West</b> - Lucy Allberry
						<br />
						<b>Regional Business Sales Manager, South </b> - Sara Stanborough
					</p>
				</div>
			</div>
		</PageWithNavigation>
	);
};

const mapStateToProps = (state: IReduxState) => ({
	user: state.auth.user
});

export const ContactPage = connect(mapStateToProps)(ContactPageBase);
