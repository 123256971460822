import React from 'react';
import axios from 'axios';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { PageWithNavigation } from '../common/Navigation/PageWithNavigation';
import { LogoCatalog } from '../Logos';
import { SetupAccountForm } from './SetupAccountForm';
import * as Routes from '../../routes';
import './SetupAccountPage.scss';
import apiEndpoint from '../../actions/api';

const rootUrl = apiEndpoint;

interface IProps extends RouteComponentProps {
	getAccountForSetup: any;
}

class BaseSetupAccountPage extends React.Component<IProps> {
	state = {
		loading: true,
		email: ''
	};

	async componentDidMount() {
		try {
			const params: any = this.props.match.params;
			const { crmToken } = params;

			const {
				data: { emailAddress }
			} = await axios.get(`${rootUrl}/user/token/${crmToken}`);

			this.setState({ email: emailAddress });
		} catch (err) {
			if (err.response.status === 400) {
				this.props.history.push(Routes.LOGIN);
			}
			console.log(JSON.stringify(err));
		} finally {
			this.setState({ loading: false });
		}
	}

	render() {
		const params: any = this.props.match.params;
		const { crmToken } = params;
		const { loading } = this.state;

		return (
			<PageWithNavigation>
				<div className="setup-account-page">
					<div className="centered">
						<img src={LogoCatalog.Logo} style={{ padding: '2em' }} alt="" />
						{loading ? (
							<Loading />
						) : (
							<>
								<div style={{ textAlign: 'center' }}>
									<h1 style={{ fontSize: '1.5em' }}>Let's get you signed up</h1>
									<h4>You'll need to create a password for your account</h4>
								</div>
								<SetupAccountForm email={this.state.email} crmToken={crmToken} />
							</>
						)}
					</div>
				</div>
			</PageWithNavigation>
		);
	}
}

const Loading: React.SFC = () => (
	<>
		<div style={{ textAlign: 'center' }}>
			<h1 style={{ fontSize: '1.5em' }}>Let's get you signed up</h1>
			<h4>We're just retrieving your account information</h4>
		</div>

		<div style={{ margin: '2em 0em 2em 0em' }}>
			<div
				className="rotateForever"
				style={{
					margin: '0px auto',
					borderRadius: '50%',
					borderTop: '4px solid #b01528',
					borderLeft: '4px solid #b01528',
					borderRight: '4px solid transparent',
					borderBottom: '4px solid #b01528',
					height: 50,
					width: 50
				}}
			/>
		</div>
	</>
);

export const SetupAccountPage = withRouter(BaseSetupAccountPage);
