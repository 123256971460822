import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { DemoTable } from './tables/DemoTable';
import * as Routes from '../../routes';
import { IReduxState } from '../../reducers';
import { IDemo } from '../../reducers/demoReducer';
import { getDemos } from '../../actions/demoActions';

interface IProps extends RouteComponentProps {
	demos: IDemo[];
	getDemos: any;
}

class BaseDemoListWidget extends React.Component<IProps> {
	componentDidMount() {
		this.props.getDemos();
	}

	render() {
		const { demos, history } = this.props;
		const pendingDemos = demos.filter(
			x =>
				['Approved', 'Requested', 'On Demo', 'Confirmed', 'Ordered', 'Pending NBSM Approval'].indexOf(
					x.status
				) !== -1
		);

		return (
			<div className="box">
				<div style={{ marginBottom: '1.5em' }}>
					<button
						className="DemoListWidget__requestButton button is-pulled-right desktop-only"
						onClick={() => history.push(Routes.REQUEST_DEMO)}
					>
						Request a demo
					</button>
					<h3 className="is-size-4 has-text-weight-bold is-inline-block">Your Upcoming and Current Demos</h3>
				</div>
				{!!pendingDemos && !!pendingDemos.length && (
					<>
						<DemoTable rows={pendingDemos} />
						<div className="has-text-centered">
							<Link to={Routes.DEMOS}>SEE ALL</Link>
						</div>
					</>
				)}
				{(!pendingDemos || !pendingDemos.length) && (
					<div className="has-text-centered" style={{ padding: '3em' }}>
						<h3>
							<p className="grey-600">
								You don't have any pending demos.
								<Link to={Routes.REQUEST_DEMO}> Want to try driving one of our cars?</Link>
							</p>
						</h3>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state: IReduxState) => ({
	demos: state.demos.filter(x => !x.end || x.end > new Date())
});

export const DemoListWidget = connect(
	mapStateToProps,
	{ getDemos }
)(withRouter(BaseDemoListWidget));
