import { IAction, ActionType } from '../actions/types';

export interface IJwt {
	expires: string;
	token: string;
}

export interface IUser {
	id: string;
	companyName: string;
	gdprDirectMail: boolean;
	gdprTelephone: boolean;
	gdprSms: boolean;
	gdprEmail: boolean;
	emailAddress: string;
}

export interface IAuthState {
	jwt?: IJwt;
	user?: IUser;
}

const emptyState: IAuthState = {
	jwt: undefined,
	user: undefined
};

export const authReducer = (state: IAuthState = loadState(), { type, payload }: IAction) => {
	let updatedState;

	switch (type) {
		case ActionType.GetToken:
			updatedState = { ...state, jwt: payload.jwt };
			if (payload.persist) saveState(updatedState);
			return updatedState;

		case ActionType.GetUser:
			updatedState = { ...state, user: payload.user };
			if (payload.persist) saveState(updatedState);
			return updatedState;

		case ActionType.Logout:
			deleteState();
			return emptyState;

		default:
			return state;
	}
};

function saveState(state: IAuthState): void {
	localStorage.setItem('auth', JSON.stringify(state));
}

function loadState(): IAuthState {
	const fromStorage = localStorage.getItem('auth');
	if (fromStorage) return JSON.parse(fromStorage);
	return {
		jwt: undefined,
		user: undefined
	};
}

function deleteState(): void {
	localStorage.removeItem('auth');
}
