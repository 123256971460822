import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { IReduxState } from '../../reducers';
import { IUser } from '../../reducers/authReducer';

interface IPrivateRouteBaseProps extends RouteProps {
    user: IUser | undefined,
    component: React.ComponentType<any>
}

const PrivateRouteBase: React.SFC<IPrivateRouteBaseProps> = ({ user, component: Component, ...rest }: IPrivateRouteBaseProps) => {
    return (
        <Route
            {...rest}
            render={props =>
                user ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};

const mapStateToProps = (state: IReduxState) => ({
    user: state.auth.user
});

export const PrivateRoute = connect(mapStateToProps)(PrivateRouteBase);

export default PrivateRoute;
