import React from 'react';
import { IAgreement } from '../../../reducers/agreementReducer';
import { formatDate } from '../../../dateHelpers';

interface IProps {
	agreement: IAgreement;
}

export const CustomerInfo: React.SFC<IProps> = ({
	agreement: { status, start, end, ucn, termsIssueType, specialTermsNotes }
}) => (
	<table className="customer-info-table">
		<tbody>
			<tr>
				<td>Status</td>
				<th>{!!status ? status.trim() : ''}</th>
			</tr>
			<tr>
				<td>Start Date</td>
				<th>{formatDate(start)}</th>
			</tr>
			<tr>
				<td>End Date</td>
				<th>{formatDate(end)}</th>
			</tr>
			<tr>
				<td>UCN</td>
				<th>{ucn}</th>
			</tr>
			<tr>
				<td>Terms Type</td>
				<th>{termsIssueType}</th>
			</tr>
			{!!specialTermsNotes ? (
				<tr>
					<td>Special Terms Notes</td>
					<th>{specialTermsNotes}</th>
				</tr>
			) : null}
		</tbody>
	</table>
);
