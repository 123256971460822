import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { IAgreement } from '../../../reducers/agreementReducer';
import { downloadLetter, agreeTerms } from '../../../actions/agreementActions';
import { AgreementTermsAndConditions } from './TermsAndConditions';
import { ImportantNotes } from './ImportantNotes';
import { SupportSuffix } from './SupportSuffix';
import { CustomerInfo } from './CustomerInfo';
import { MobileCollapsibleSection } from '../../common/MobileCollapsibleSection';
import { IReduxState } from '../../../reducers';

const emptyAgreement: IAgreement = {
	id: '',
	status: '',
	ucn: '',
	companyName: '',
	start: new Date(),
	end: new Date(),
	termsIssueType: '',
	cxAcknowledgementDate: new Date(),
	support: [],
	salesChannel: ''
};

interface ReactProps {
	agreementId: string;
	history: any;
}

interface ReduxProps {
	agreement?: IAgreement;
}

interface ReduxActions {
	downloadLetter: any;
	agreeTerms: any;
}

interface IProps extends ReactProps, ReduxProps, ReduxActions {}

const BaseKiaTemplate: React.SFC<IProps> = ({ agreement = emptyAgreement, downloadLetter, agreeTerms, history }) => {
	const { id, status, companyName, support, cxAcknowledgementDate } = agreement;
	const trimmedStatus = (status || '').trim();
	const notAgreed = !cxAcknowledgementDate && trimmedStatus !== 'Expired';

	return (
		<div className="agreement-template">
			<div className="agreement-template-header">
				<div>
					<h2>
						<span className="desktop-only">Kia Business Support </span>
						Agreement for <span className="company-name">{companyName}</span>
					</h2>
					<div className="download-button">
						{trimmedStatus !== 'Expired' && (
							<button onClick={() => downloadLetter(id)}>
								<i className="fas fa-download" />
								Download as PDF
							</button>
						)}
					</div>
				</div>
			</div>
			<div className="agreement-template-content">
				{notAgreed ? (
					<div className="not-agreed-warning">
						<i className="fas fa-exclamation-triangle desktop-only" />
						<strong>
							{' '}
							These terms have been updated, but you have not yet approved them. Please read them
							carefully, then click the button below.
						</strong>
						<br />
						<button className="button is-primary" onClick={() => agreeTerms(id, toastr, history)}>
							Yes, I agree to these terms
						</button>
					</div>
				) : (
					<>{''}</>
				)}
				<section>
					<CustomerInfo agreement={agreement} />
				</section>
				<MobileCollapsibleSection initiallyCollapsed={true} title="Support Terms">
					<table className="table is-fullwidth" style={{ marginTop: '0.5em' }}>
						<tbody>
							{support
								.filter(x => !!x.support)
								.sort((a, b) => (a.descriptor > b.descriptor ? 1 : -1))
								.map(({ descriptor, support }) => (
									<tr key={descriptor}>
										<td>{descriptor.toString().replace(' Support', '')}</td>
										<td>
											{support}
											<SupportSuffix agreement={agreement} />
										</td>
									</tr>
								))}
							<ImportantNotes agreement={agreement} />
						</tbody>
					</table>
				</MobileCollapsibleSection>
				<MobileCollapsibleSection initiallyCollapsed={true} title="Terms & Conditions">
					<AgreementTermsAndConditions agreement={agreement} />
				</MobileCollapsibleSection>
			</div>
		</div>
	);
};

const mapStateToProps = (state: IReduxState, reactProps: ReactProps): ReduxProps => ({
	agreement: (state.agreements || []).find(x => x.id === reactProps.agreementId)
});

export const KiaTemplate = connect(
	mapStateToProps,
	{ downloadLetter, agreeTerms }
)(BaseKiaTemplate);
