import React from 'react';
import { Link } from 'react-router-dom';
import { Segment, Image } from 'semantic-ui-react';
import { PageWithNavigation } from '../common/Navigation/PageWithNavigation';
import * as Routes from '../../routes';
import { LogoCatalog } from '../Logos';
import { ResetPasswordForm } from './ResetPasswordForm';

export const ResetPasswordPage: React.SFC = () => (
	<PageWithNavigation>
		<div className="reset-password-page">
			<div>
				<Segment attached="top" textAlign="center">
					<Image src={LogoCatalog.LogoWithTagline} />
				</Segment>
				<Segment attached>
					<ResetPasswordForm />
				</Segment>
				<Segment basic textAlign="right" style={{ marginTop: 20, textAlign: 'right' }}>
					<Link to={Routes.LOGIN}>Back to login</Link>
				</Segment>
			</div>
		</div>
	</PageWithNavigation>
);
