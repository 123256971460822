import React from 'react';
import { PageWithNavigation } from '../common/Navigation/PageWithNavigation';
import { ChangePasswordForm } from './ChangePasswordForm';
import { MarketingPreferenceForm } from './MarketingPreferenceForm';

export const SettingsPage: React.SFC = () => (
	<PageWithNavigation>
		<div className="container settings-page">
			<div className="box">
				<div className="columns">
					<div className="column is-4" style={{ padding: '2rem' }}>
						<h3 className="SettingsPage__subheading is-size-4">Change your password</h3>
					</div>
					<div className="column" style={{ padding: '2rem' }}>
						<ChangePasswordForm />
					</div>
				</div>
				<hr />
				<div className="columns">
					<div className="column is-4" style={{ padding: '2rem' }}>
						<h3 className="SettingsPage__subheading is-size-4">Marketing preferences</h3>
						<p className="explanation-text">
							If you do not want Kia to contact you for marketing purposes, please uncheck the relevant
							boxes below and click Save Preferences.
						</p>
					</div>
					<div className="column" style={{ padding: '2em' }}>
						<MarketingPreferenceForm />
					</div>
				</div>
			</div>
		</div>
	</PageWithNavigation>
);
