import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { PageWithNavigation } from '../common/Navigation/PageWithNavigation';
import { AgreementsTable } from './AgreementsTable';
import { IReduxState } from '../../reducers';
import { IAgreement } from '../../reducers/agreementReducer';
import { getAgreements } from '../../actions/agreementActions';

interface IProps extends RouteComponentProps {
	agreements: IAgreement[];
	getAgreements: any;
}

class BaseAgreementsListPage extends React.Component<IProps> {
	state = {
		currentTab: 'Active'
	};

	componentDidMount() {
		this.props.getAgreements();
	}

	changeTab = () => this.setState({ currentTab: this.state.currentTab === 'Active' ? 'Archived' : 'Active' });

	render() {
		const { agreements } = this.props;
		return (
			<PageWithNavigation>
				<div className="agreement-list-page-header">
					<h2>Your Agreements</h2>
				</div>
				<div style={{ padding: '1em', backgroundColor: 'white' }}>
					<AgreementsTable rows={agreements} />
				</div>
			</PageWithNavigation>
		);
	}
}

const mapStateToProps = (state: IReduxState) => ({
	agreements: state.agreements
});

export const AgreementsListPage = connect(
	mapStateToProps,
	{ getAgreements }
)(withRouter(BaseAgreementsListPage));
