import React from 'react';

interface IProps {
	title: string;
	value: string;
}

export const FleetProfileItem: React.SFC<IProps> = ({ title, value }) => (
	<div className="column">
		<h4>{title}</h4>
		<b>{value}</b>
	</div>
);
