import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getFleetProfile } from '../../actions/fleetProfileActions';
import { IReduxState } from '../../reducers';
import { IFleetProfile } from '../../reducers/fleetProfileReducer';
import { FleetProfileItem } from './FleetProfileItem';

interface IProps {
	companyName: string;
	fleetProfile: IFleetProfile;
	getFleetProfile: any;
	loading: boolean;
}

const BaseFleetProfileWidget: React.SFC<IProps> = ({
	companyName,
	fleetProfile: { fleetSize, changeCycleMonths = [''], currentFunders, numberOfCars, numberOfVans, purchaseMethod },
	getFleetProfile
}) => {
	useEffect(
		() => {
			getFleetProfile();
		},
		[getFleetProfile]
	);

	return (
		<div className="box fleet-profile-widget">
			<h3 className="is-size-4 has-text-weight-bold" style={{ marginBottom: '0.7em' }}>
				Fleet Profile for {companyName}
			</h3>
			<div className="columns has-text-centered">
				<FleetProfileItem title="Total Size" value={numberWithCommas(fleetSize) || 'Not Known'} />
				<FleetProfileItem title="Cars" value={numberWithCommas(numberOfCars) || 'Not Known'} />
				<FleetProfileItem title="Vans" value={numberWithCommas(numberOfVans) || 'Not Known'} />
				<FleetProfileItem title="Change every" value={commaSeparate(changeCycleMonths)} />
				<FleetProfileItem title="Purchase Method" value={commaSeparate(purchaseMethod)} />
				<FleetProfileItem title="Funded By" value={commaSeparate(currentFunders)} />
			</div>
			<div className="is-size-7 has-text-centered">
				If this information is inaccurate, please call us on <a href="tel:01932 283945">01932 283945</a> or
				email us at <a href="mailto:enquiries@kiabusinesscentre.co.uk">enquiries@kiabusinesscentre.co.uk</a>.
			</div>
		</div>
	);
};

function numberWithCommas(x: number | undefined): string {
	if (!x) return '';
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function commaSeparate(arr?: string[]): string {
	return arr && arr.length ? arr.join(', ') : 'Not Known';
}

const mapStateToProps = (state: IReduxState) => ({
	companyName: (state.auth.user && state.auth.user.companyName) || 'Your Company',
	loading: state.loading.fleetProfile,
	fleetProfile: state.fleetProfile
});

export const FleetProfileWidget = connect(
	mapStateToProps,
	{ getFleetProfile }
)(BaseFleetProfileWidget);
