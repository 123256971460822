import _ from 'lodash';
import axios from 'axios';
import { IReduxState } from './../reducers/index';

const map = {
	[process.env.REACT_APP_LOCAL_HOSTNAME as string]: process.env.REACT_APP_LOCAL_ENDPOINT,
	[process.env.REACT_APP_DEV_HOSTNAME as string]: process.env.REACT_APP_DEV_ENDPOINT,
	[process.env.REACT_APP_UAT_HOSTNAME as string]: process.env.REACT_APP_UAT_ENDPOINT,
	[process.env.REACT_APP_LIVE_HOSTNAME as string]: process.env.REACT_APP_LIVE_ENDPOINT
};

const currentHostname = _.get(window, 'location.hostname', 'INVALID');
const apiEndpoint = map[currentHostname];

if (!apiEndpoint) {
	throw new Error(`Unable to match config for hostname: "${currentHostname}"`);
}

export default apiEndpoint;

export interface IApi {
	get: (endpoint: string, customHeaders?: any | undefined) => Promise<any>;
	put: (endpoint: string, data: any | undefined) => Promise<any>;
	post: (endpoint: string, data: any | undefined) => Promise<any>;
	delete: (endpoint: string, data: any | undefined) => Promise<any>;
}

interface IHeaders {
	'Content-Type': string;
	'Cache-Control': string;
	Pragma: string;
	Authorization?: string;
}

export class Api implements IApi {
	private getState: () => IReduxState;

	constructor(getState: () => IReduxState) {
		this.getState = getState;
	}

	get(endpoint: string, customHeaders?: any | undefined): Promise<any> {
		const url = this.buildUrl(endpoint);
		const headers = {
			...this.buildHeaders(),
			...(customHeaders || {})
		};
		return axios.get(url, { headers });
	}

	put(endpoint: string, data: any | undefined): Promise<any> {
		const url = this.buildUrl(endpoint);
		const headers = this.buildHeaders();
		return axios.put(url, data, { headers });
	}

	post(endpoint: string, data: any | undefined): Promise<any> {
		const url = this.buildUrl(endpoint);
		const headers = this.buildHeaders();
		return axios.post(url, data, { headers });
	}

	delete(endpoint: string, data: any | undefined): Promise<any> {
		const url = this.buildUrl(endpoint);
		const headers = this.buildHeaders();
		return axios.delete(url, { headers, params: { ...data } });
	}

	private buildUrl(endpoint: string): string {
		return `${apiEndpoint}${endpoint}`;
	}

	private buildHeaders(): IHeaders {
		const headers: IHeaders = {
			'Content-Type': 'application/json',
			'Cache-Control': 'no-cache',
			Pragma: 'no-cache'
		};

		const jwt = this.getState().auth.jwt;
		if (jwt) headers.Authorization = `Bearer ${jwt.token}`;

		return headers;
	}
}
