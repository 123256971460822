import React, { useEffect } from 'react';
import { PageWithNavigation } from '../common/Navigation/PageWithNavigation';
import { DemoListWidget } from '../demos/DemoListWidget';
import { AgreementsListWidget } from '../agreements/AgreementsListWidget';
import { FleetProfileWidget } from '../fleetProfile/FleetProfileWidget';
import { TermsHaveChangedModal } from './TermsHaveChangedModal';

export const DashboardPage: React.SFC = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<PageWithNavigation>
			<div className="container">
				<FleetProfileWidget />
				<AgreementsListWidget />
				<DemoListWidget />
				<TermsHaveChangedModal />
			</div>
		</PageWithNavigation>
	);
};
