import React from 'react';
import { connect } from 'react-redux';
import { IDemo } from '../../reducers/demoReducer';
import { DemoTable } from './tables/DemoTable';
import { IReduxState } from '../../reducers';
import { getDemos } from '../../actions/demoActions';
import { PageWithNavigation } from '../common/Navigation/PageWithNavigation';
import { RouteComponentProps } from 'react-router';
import * as Routes from '../../routes';

interface IProps extends RouteComponentProps {
	demos: IDemo[];
	getDemos: any;
}

class BaseDemoListPage extends React.Component<IProps> {
	componentDidMount() {
		this.props.getDemos();
	}

	render() {
		const { demos, history } = this.props;
		return (
			<PageWithNavigation>
				<div className="demo-page-header">
					<div>
						<h2>Your Demos</h2>
						<div className="request-demo-button">
							<button onClick={() => history.push(Routes.REQUEST_DEMO)}>
								<i className="fas fa-plus" /> <span>Request a demo</span>
							</button>
						</div>
					</div>
				</div>
				<div style={{ padding: '1em', backgroundColor: 'white' }}>
					<DemoTable rows={demos} />
				</div>
			</PageWithNavigation>
		);
	}
}

const mapStateToProps = (state: IReduxState) => ({
	demos: state.demos
});

export const DemoListPage = connect(
	mapStateToProps,
	{ getDemos }
)(BaseDemoListPage);
